import React from 'react'
import { APP_VERSION, theme } from '../../../lib'
import { getNativeAppVersion } from '../../../services'

export const AppVersion = () => {
  const nativeAppVersion = getNativeAppVersion()
  const completeAppVersion = nativeAppVersion ? `v/${nativeAppVersion} (${APP_VERSION})` : `v/${APP_VERSION}`
  const isTest = !!window.location.host.match(/(test)|(:\d{4})/gi)

  return (
    <div className="flex flex-col items-center">
      <img src={theme.images.poweredByTomraLogo} alt="Powered by TOMRA" className="max-h-4" />
      <small>
        {completeAppVersion}
        {isTest && ' (test)'}
      </small>
    </div>
  )
}
