import React from 'react'

type Props = {
  color?: string
  size?: string
}

export const FavoriteLocationIcon = (props: Props) => (
  <svg
    width={props.size || '1.6rem'}
    height={props.size || '1.6rem'}
    viewBox="0 0 18 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>FavoriteLocationIcon</title>
    <path
      d="M2.63604 2.63604C6.15076 -0.87868 11.8492 -0.87868 15.364 2.63604C18.8787 6.15076 18.8787 11.8492 15.364 15.364L14.1371 16.5773C13.2328 17.4649 12.0595 18.6059 10.6167 20.0008C9.71518 20.8725 8.28483 20.8724 7.3834 20.0006L3.77495 16.4906C3.32144 16.0453 2.94182 15.6697 2.63604 15.364C-0.87868 11.8492 -0.87868 6.15076 2.63604 2.63604ZM14.2676 3.73235C11.3584 0.823113 6.64159 0.823113 3.73235 3.73235C0.823113 6.64159 0.823113 11.3584 3.73235 14.2676L5.26942 15.7844C6.11585 16.6128 7.17992 17.6469 8.46127 18.8861C8.76175 19.1767 9.23853 19.1768 9.53905 18.8862L13.0481 15.4738C13.5328 14.998 13.9393 14.596 14.2676 14.2676C17.1769 11.3584 17.1769 6.64159 14.2676 3.73235Z"
      fill={props.color || 'currentColor'}
    />
    <path
      d="M8.42619 5.3567C8.66091 4.8811 9.33909 4.8811 9.57381 5.3567L10.463 7.15848L12.4514 7.44741C12.9763 7.52367 13.1858 8.16866 12.8061 8.53886L11.3673 9.94135L11.7069 11.9217C11.7966 12.4444 11.2479 12.8431 10.7785 12.5963L9 11.6613L7.22154 12.5963C6.7521 12.8431 6.20343 12.4444 6.29309 11.9217L6.63274 9.94135L5.19394 8.53886C4.81415 8.16866 5.02372 7.52367 5.54857 7.44741L7.53696 7.15848L8.42619 5.3567Z"
      fill={props.color || 'currentColor'}
    />
  </svg>
)
