import React, { useState } from 'react'
import { intl } from '../../../lib'
import {
  DarkModePicker_SelectTheme,
  DarkModePicker_LightTheme,
  DarkModePicker_DarkTheme,
  DarkModePicker_SystemTheme
} from '../../../translations/messages'
import DarkModeIcon from '@tomra/consumer-customer-design-system/config/svg/DarkModeIcon.svg?raw'

export const DarkModePicker = () => {
  const [selectedTheme] = useState<'light' | 'dark' | 'system'>(window.localStorage.darkMode || 'system')

  const setTheme = async newTheme => {
    window.localStorage.setItem('darkMode', newTheme)
    window.location.reload()
  }

  return (
    <div className="relative flex items-center overflow-hidden">
      <div
        className="pointer-none pointer-events-none absolute right-0 top-0"
        dangerouslySetInnerHTML={{ __html: DarkModeIcon }}
      />

      <select
        style={{ background: 'none' }}
        className="h-[1.6rem] w-[1.6rem] cursor-pointer appearance-none border-none text-transparent"
        aria-label={intl.formatMessage(DarkModePicker_SelectTheme)}
        value={selectedTheme}
        onChange={evt => setTheme(evt.target.value)}
      >
        <option className="text-[initial]" value="dark">
          {intl.formatMessage(DarkModePicker_DarkTheme)}
        </option>

        <option className="text-[initial]" value="light">
          {intl.formatMessage(DarkModePicker_LightTheme)}
        </option>

        <option className="text-[initial]" value="system">
          {intl.formatMessage(DarkModePicker_SystemTheme)}
        </option>
      </select>
    </div>
  )
}
