import React, { useState } from 'react'
import { EmailIcon, ErrorIcon, ArrowRightIcon, LogoutIcon } from '../icons'
import { Loading } from '../shared'
import { FIREBASE, removeItemFromWebStorage, intl } from '../../lib'
import {
  ScheduledDeletePage_ContactInfo,
  ScheduledDeletePage_Warning,
  Common_Logout
} from '../../translations/messages'

export const ScheduledDeletePage = ({ scheduledDeleteAt }: { scheduledDeleteAt: string }) => {
  const [loadingLogout, setLoadingLogout] = useState(false)
  const milliSecondsUntilDelete = Date.parse(scheduledDeleteAt) - Date.now()
  const daysUntilDelete = Math.floor(milliSecondsUntilDelete / (1000 * 60 * 60 * 24))

  return (
    <div className="relative h-screen bg-yellow dark:bg-yellow/30 flex flex-col items-center justify-around p-10">
      <button
        className="btn-icon absolute top-5 right-5"
        aria-label={intl.formatMessage(Common_Logout)}
        onClick={() => {
          setLoadingLogout(true)
          removeItemFromWebStorage('lastAuthTimestamp')
          FIREBASE.auth.signOut()
        }}
      >
        {loadingLogout ? <Loading /> : <LogoutIcon size="2rem" />}
      </button>

      <ErrorIcon size="4rem" />
      <h1 className="text-3xl">{intl.formatMessage(ScheduledDeletePage_Warning, { daysUntilDelete })}</h1>

      <p>{intl.formatMessage(ScheduledDeletePage_ContactInfo)}</p>

      <a
        className="btn flex items-center justify-between space-x-3 w-full"
        href="mailto:support.tomraproducts@tomra.com"
      >
        <EmailIcon />
        <small>support.tomraproducts@tomra.com</small>
        <ArrowRightIcon />
      </a>
    </div>
  )
}
