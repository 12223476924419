import { useState, useEffect } from 'react'

const useCountdown = (initialSeconds: number, onCountdownEnd: () => void) => {
  const [secondsLeft, setSecondsLeft] = useState(0)

  const startCountdown = () => setSecondsLeft(initialSeconds)
  const stopCountdown = () => setSecondsLeft(0)

  useEffect(() => {
    if (secondsLeft === 0) return

    const timer = setInterval(() => {
      setSecondsLeft(prev => {
        if (prev <= 1) {
          clearInterval(timer)
          onCountdownEnd()
          return 0
        }
        return prev - 1
      })
    }, 1000)

    return () => clearInterval(timer)
  }, [secondsLeft, onCountdownEnd])

  return { secondsLeft, startCountdown, stopCountdown }
}

export default useCountdown
