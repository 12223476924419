import React from 'react'
import { ActivitiesSection } from './ActivitiesSection'
import { RefundStatistics } from './RefundStatistics'
import { FindLocation } from '../home/favoriteLocation/FindLocation'
import { intl } from '../../lib'
import { ActivitiesPage_NoActivities, Common_Refunds } from '../../translations/messages'
import { SendDonationTaxReceipts } from './taxReceiptDrawer/SendDonationTaxReceipts'
import { RetryRefundsButton } from './RetryRefundsButton'

type Props = {
  activities: RefundType[]
  now?: Date
}

type MonthWithActivitiesMap = {
  [monthName: string]: RefundType[]
}

function groupActivitiesByMonth(activities: RefundType[], now: Date): MonthWithActivitiesMap {
  const initialEmptyMap = {}
  const currentYear = now.getFullYear()

  return activities.reduce((mapByMonthAndYear, currentActivity) => {
    const activityDate = new Date(currentActivity.timestamp)
    const year = activityDate.getFullYear()
    const month = intl.formatDate(currentActivity.timestamp, { month: 'long' })
    const monthAndYear = currentYear === activityDate.getFullYear() ? month : `${month} ${year}`
    const existingActivitiesForMonth = mapByMonthAndYear[monthAndYear] || []
    const newActivities = [...existingActivitiesForMonth, currentActivity]

    // create a new map object containing the old contents in addition
    // to the new list of activites for this particular month,
    // mostly because mutating data is the root of all evil
    return { ...mapByMonthAndYear, [monthAndYear]: newActivities }
  }, initialEmptyMap)
}

function createSectionsByMonth(activities: RefundType[], now: Date): JSX.Element[] {
  const activitiesByMonth = groupActivitiesByMonth(activities, now)
  const allMonthNames = Object.keys(activitiesByMonth)
  return allMonthNames.map(monthName => (
    <ActivitiesSection title={monthName} activities={activitiesByMonth[monthName]} key={monthName} />
  ))
}

export const ActivitiesPage = ({ activities, now = new Date() }: Props) => {
  if (activities.length === 0) {
    return (
      <div className="flex flex-col justify-between p-10 absolute top-0 left-1/2 -translate-x-1/2 max-w-mobile h-full w-full text-center">
        <div />

        <h1 className="text-3xl">{intl.formatMessage(ActivitiesPage_NoActivities)}</h1>

        <FindLocation />
      </div>
    )
  }

  return (
    <div className="mb-5 space-y-8 animate-fadeIn">
      <div className="flex items-center justify-between mt-14">
        <h1 className="text-3xl">{intl.formatMessage(Common_Refunds)}</h1>
        <SendDonationTaxReceipts />
      </div>

      <RefundStatistics />
      <RetryRefundsButton />

      {createSectionsByMonth(activities, now)}
    </div>
  )
}
