import React from 'react'
import { intl } from '../../lib'
import { Link, useLocation, Outlet } from 'react-router-dom'
import { BackButton } from '../shared'
import { Common_Charities, Common_BrowseCharities, CharitiesPage_MyDonations } from '../../translations/messages'

export const CharitiesPage = () => {
  const { pathname } = useLocation()

  return (
    <div className="animate-fadeIn">
      <BackButton
        to={pathname.match(/charities\/.{15,}/gi) ? '.' : '..'}
        textColor="var(--colors-theme-charity-text)"
      />

      {!!pathname.match(/charities$|charities\/donations$/gi) && (
        <div className="flex flex-col items-center w-full bg-gradient-to-tl from-[var(--colors-theme-charity-secondary)] to-[var(--colors-theme-charity-primary)]">
          <div className="max-w-mobile mx-auto w-full p-10">
            <h1 className="text-3xl my-10 text-[var(--colors-theme-charity-text)]">
              {intl.formatMessage(Common_Charities)}
            </h1>

            <div className="tabs small">
              <Link to="donations" className={`${pathname === '/charities/donations' ? 'active' : ''}`}>
                {intl.formatMessage(CharitiesPage_MyDonations)}
              </Link>
              <Link to="." className={`${pathname === '/charities' ? 'active' : ''}`}>
                {intl.formatMessage(Common_BrowseCharities)}
              </Link>
            </div>
          </div>
        </div>
      )}

      <Outlet />
    </div>
  )
}
