import React from 'react'
import { intl } from '../../lib'
import { Currency } from '../shared'
import payoutErrorMessagesMap from './payoutErrorMessagesMap'
import {
  AcivityHistory_PendingTransferToPayPal,
  ActivityHistory_AddedFromBag,
  ActivityHistory_AttemptedBankTransfer,
  ActivityHistory_AttemptedDonation,
  ActivityHistory_AttemptedTransferToPayPal,
  Common_BagRegistered,
  Common_Bagtag,
  ActivityHistory_BankTransfer,
  ActivityHistory_CreatedEVoucher,
  ActivityHistory_DigitalVoucherRedeemed,
  ActivityHistory_DonateToCharityCampaign,
  ActivityHistory_ExpiredVoucher,
  ActivityHistory_FailedToCreateDigitalVoucher,
  ActivityHistory_PendingBankTransfer,
  ActivityHistory_PendingDonation,
  ActivityHistory_RecycledFor,
  ActivityHistory_TransferToPayPal
} from '../../translations/messages'

const payoutMessageMap = (paymentMethod: PayoutMethodType['type'], recipient?: string) => {
  switch (paymentMethod) {
    case 'E_VOUCHER':
      return intl.formatMessage(ActivityHistory_DigitalVoucherRedeemed)
    case 'PAYPAL':
      return intl.formatMessage(ActivityHistory_TransferToPayPal)
    case 'DONATION':
      return intl.formatMessage(ActivityHistory_DonateToCharityCampaign, {
        recipient: recipient ? recipient : 'charity campaign'
      })
    case 'BANK_TRANSFER':
      return intl.formatMessage(ActivityHistory_BankTransfer)
  }
}

const pendingPayoutMessageMap = (paymentMethod: PayoutMethodType['type'], recipient?: string) => {
  switch (paymentMethod) {
    case 'E_VOUCHER':
      return intl.formatMessage(ActivityHistory_CreatedEVoucher)
    case 'PAYPAL':
      return intl.formatMessage(AcivityHistory_PendingTransferToPayPal)
    case 'DONATION':
      return intl.formatMessage(ActivityHistory_PendingDonation, {
        recipient: recipient ? recipient : 'charity campaign'
      })
    case 'BANK_TRANSFER':
      return intl.formatMessage(ActivityHistory_PendingBankTransfer)
  }
}

const payoutWithErrorMessageMap = (paymentMethod: PayoutMethodType['type'], recipient?: string) => {
  switch (paymentMethod) {
    case 'E_VOUCHER':
      return intl.formatMessage(ActivityHistory_FailedToCreateDigitalVoucher)
    case 'PAYPAL':
      return intl.formatMessage(ActivityHistory_AttemptedTransferToPayPal)
    case 'DONATION':
      return intl.formatMessage(ActivityHistory_AttemptedDonation, {
        recipient: recipient ? recipient : 'charity campaign'
      })
    case 'BANK_TRANSFER':
      return intl.formatMessage(ActivityHistory_AttemptedBankTransfer)
  }
}

function renderActivityDescription(activityHistory: RefundHistoryType, status: RefundType['status']) {
  const { recyclingMethod, activityType, details, amount, currency } = activityHistory

  if (activityType === 'BAG_SCANNED') {
    return intl.formatMessage(Common_BagRegistered)
  }

  if (activityType === 'PAYOUT' && details) {
    switch (status) {
      case 'SUCCESSFUL':
        return payoutMessageMap(details.paymentMethod, details.recipient)
      case 'PENDING':
        return pendingPayoutMessageMap(details.paymentMethod, details.recipient)
      case 'EXPIRED':
        return intl.formatMessage(ActivityHistory_ExpiredVoucher)
      default:
        return payoutWithErrorMessageMap(details.paymentMethod, details.recipient)
    }
  }

  if (activityType === 'RECYCLING' && recyclingMethod === 'BAG') {
    return (
      <div className="flex items-center space-x-1">
        <Currency amount={amount} currency={currency} />
        {intl.formatMessage(ActivityHistory_AddedFromBag)}
      </div>
    )
  }

  if (activityType === 'RECYCLING' && recyclingMethod === 'RVM') {
    return (
      <div className="flex items-center space-x-1">
        <span>{intl.formatMessage(ActivityHistory_RecycledFor)}</span>
        <Currency amount={amount || 0} currency={currency || 'AUD'} />
      </div>
    )
  }
}

function getPayoutErrorMessage(activities: RefundHistoryType[]) {
  // @ts-ignore
  const { details } = activities.find(({ details }) => !!details && details.errorCategory) || {}

  if (details) {
    const { paymentMethod, errorCategory } = details

    return intl.formatMessage(
      payoutErrorMessagesMap[paymentMethod][errorCategory] || payoutErrorMessagesMap[paymentMethod].DEFAULT
    )
  }
}

type Props = {
  bagtag: string
  activities: RefundHistoryType[]
  status: RefundType['status']
}

export const ActivityHistory = ({ bagtag, activities, status }: Props) => {
  const errorMessage = getPayoutErrorMessage(activities)

  return (
    <div className="relative flex flex-col card px-5 pt-10 pb-5 bottom-7 space-y-5">
      {errorMessage && (
        <>
          <div className="space-y-3">
            <span>{errorMessage}</span>
          </div>

          <hr />
        </>
      )}

      {bagtag && (
        <p className="flex items-center space-x-2 text-sky-blue">
          <span>{intl.formatMessage(Common_Bagtag)}</span>
          <span>{bagtag}</span>
        </p>
      )}

      {activities.map((activity: RefundHistoryType) => {
        return (
          <div key={activity.id + activity.activityType} className="flex items-center space-x-2">
            {activity.timestamp && (
              <>
                <div className="flex items-center space-x-1">
                  <span>{intl.formatDate(activity.timestamp, { month: 'short', day: '2-digit' })}</span>
                  <span>{intl.formatTime(activity.timestamp)}</span>
                </div>

                <span>-</span>
              </>
            )}

            <span>{renderActivityDescription(activity, status)}</span>
          </div>
        )
      })}
    </div>
  )
}
