import React, { useEffect, FunctionComponent } from 'react'
import { createPortal } from 'react-dom'
import { CloseIcon } from '../../icons'

type Props = {
  children?: React.ReactNode
  isOpen: boolean
  onClose?: () => void
  sideMounted?: boolean
}

export const Modal: FunctionComponent<Props> = ({ children, isOpen, onClose, sideMounted = false }) => {
  const bottomDrawerClasses = 'card fixed max-h-[90vh] overflow-y-scroll z-20 bottom-0 left-0 w-full animate-slideIn'
  const sideDrawerClasses =
    'card fixed z-20 top-0 right-0 h-full max-w-mobile w-5/6 overflow-y-scroll animate-slideInLeft'

  useEffect(() => {
    const handleKeydown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isOpen) {
        onClose?.()
      }
    }

    if (onClose) {
      document.addEventListener('keydown', handleKeydown)
    }

    return () => {
      document.removeEventListener('keydown', handleKeydown)
    }
  }, [isOpen, onClose])

  return isOpen
    ? createPortal(
        <div>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
          <div
            className="fixed top-0 left-0 w-full h-full bg-black/30 backdrop-blur-sm z-10 animate-fadeIn"
            role="dialog"
            aria-label="dialog"
            onClick={() => onClose?.()}
          />

          <div
            className={sideMounted ? sideDrawerClasses : bottomDrawerClasses}
            role="dialog"
            aria-label="dialog"
            id={sideMounted ? 'sideDrawer' : 'drawer'}
          >
            {children}

            {onClose && (
              <button className="btn-icon absolute top-3 right-3" onClick={onClose} aria-label="close">
                <CloseIcon size="1.33rem" />
              </button>
            )}
          </div>
        </div>,
        document.getElementById('root') as HTMLDivElement
      )
    : null
}
