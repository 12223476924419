import { sharedImages } from '../sharedImages'
import { mytomraImages } from './images'
import { ENV } from '../../environment'

// Note! These values are only available runtime
export const config = {
  name: 'myTOMRA',
  domain: 'https://app.mytomra.com',
  showCountryPicker: true,
  appLinks: {
    ios: 'https://itunes.apple.com/au/app/id1303242160',
    android: 'http://play.google.com/store/apps/details?id=com.tomra.digital.mytomra'
  },
  termsOfService: `${window.location.origin}/docs/mytomra/${ENV}/terms-of-service.html`,
  privacyPolicy: `${window.location.origin}/docs/mytomra/${ENV}/privacy-policy.html`,
  faq: `${window.location.origin}/docs/mytomra/${ENV}/faq.html`,
  images: {
    ...sharedImages,
    ...mytomraImages
  },
  setThemeColors: () => {
    const root = document.documentElement
    const isDarkMode = root.classList.contains('dark')

    root.style.setProperty('--colors-theme-home-primary', isDarkMode ? 'rgba(31, 192, 218, 0.5)' : 'rgb(31, 192, 218)')
    root.style.setProperty(
      '--colors-theme-home-secondary',
      isDarkMode ? 'rgba(114, 200, 175, 0.5)' : 'rgb(114, 200, 175)'
    )
    root.style.setProperty('--colors-theme-home-text', isDarkMode ? 'white' : 'black')

    root.style.setProperty('--colors-theme-login-primary', isDarkMode ? 'rgba(31, 192, 218, 0.5)' : 'rgb(31, 192, 218)')
    root.style.setProperty(
      '--colors-theme-login-secondary',
      isDarkMode ? 'rgba(114, 200, 175, 0.5)' : 'rgb(114, 200, 175)'
    )
    root.style.setProperty('--colors-theme-login-text', isDarkMode ? 'white' : 'black')

    root.style.setProperty(
      '--colors-theme-charity-primary',
      isDarkMode ? 'rgba(226, 125, 188, 0.5)' : 'rgb(226, 125, 188)'
    )
    root.style.setProperty(
      '--colors-theme-charity-secondary',
      isDarkMode ? 'rgba(255, 210, 230, 0.5)' : 'rgb(255, 210, 230)'
    )
    root.style.setProperty('--colors-theme-charity-text', isDarkMode ? 'white' : 'black')

    root.style.setProperty(
      '--colors-theme-achievements-primary',
      isDarkMode ? 'rgba(114, 200, 175, 0.5)' : 'rgb(114, 200, 175)'
    )
    root.style.setProperty(
      '--colors-theme-achievements-secondary',
      isDarkMode ? 'rgba(0, 150, 142, 0.5)' : 'rgb(0, 150, 142)'
    )
    root.style.setProperty('--colors-theme-achievements-text', isDarkMode ? 'white' : 'black')

    root.style.setProperty(
      '--colors-theme-primary-button',
      isDarkMode ? 'rgba(114, 200, 175, 0.5)' : 'rgb(114, 200, 175)'
    )
    root.style.setProperty('--colors-theme-primary-button-text', isDarkMode ? 'white' : 'black')
  },
  achievements: {
    'ACHIEVEMENT_CUMULATIVE_ITEM_COUNT-1': 'Novice Recycler',
    'ACHIEVEMENT_CUMULATIVE_ITEM_COUNT-2': 'Apprentice Recycler',
    'ACHIEVEMENT_CUMULATIVE_ITEM_COUNT-3': 'Master Recycler',
    'ACHIEVEMENT_CUMULATIVE_ITEM_COUNT-4': 'Legend Recycler',
    'ACHIEVEMENT_CUMULATIVE_METAL_ITEMS_COUNT-1': 'Can Halen',
    'ACHIEVEMENT_CUMULATIVE_METAL_ITEMS_COUNT-2': 'Soda Zeppelin',
    'ACHIEVEMENT_CUMULATIVE_METAL_ITEMS_COUNT-3': 'Aluminum Maiden',
    'ACHIEVEMENT_CUMULATIVE_METAL_ITEMS_COUNT-4': 'Recycling at the Machine',
    'ACHIEVEMENT_CUMULATIVE_PLASTIC_ITEMS_COUNT-1': 'Ocean Advocate',
    'ACHIEVEMENT_CUMULATIVE_PLASTIC_ITEMS_COUNT-2': 'Turtle Buddy',
    'ACHIEVEMENT_CUMULATIVE_PLASTIC_ITEMS_COUNT-3': 'Seal Savior',
    'ACHIEVEMENT_CUMULATIVE_PLASTIC_ITEMS_COUNT-4': 'Whale Warrior',
    'ACHIEVEMENT_UNIQUE_LOCATION_COUNT-1': 'Traveler',
    'ACHIEVEMENT_UNIQUE_LOCATION_COUNT-2': 'Explorer',
    'ACHIEVEMENT_UNIQUE_LOCATION_COUNT-3': 'Nomad',
    'ACHIEVEMENT_UNIQUE_LOCATION_COUNT-4': 'Globetrotter',
    'ACHIEVEMENT_CUMULATIVE_SESSION_COUNT-1': 'Discovering the Loop',
    'ACHIEVEMENT_CUMULATIVE_SESSION_COUNT-2': 'Exploring the Loop',
    'ACHIEVEMENT_CUMULATIVE_SESSION_COUNT-3': 'Perfecting the Loop',
    'ACHIEVEMENT_CUMULATIVE_SESSION_COUNT-4': 'Infinite Loop',
    'ACHIEVEMENT_CUMULATIVE_BAG_COUNT-1': 'Pile of Bags',
    'ACHIEVEMENT_CUMULATIVE_BAG_COUNT-2': 'Heap of Bags',
    'ACHIEVEMENT_CUMULATIVE_BAG_COUNT-3': 'Hill of Bags',
    'ACHIEVEMENT_CUMULATIVE_BAG_COUNT-4': 'Mountain of Bags',
    'ACHIEVEMENT_CUMULATIVE_DIGITAL_PAYOUT_AMOUNT-1': 'Cloud Cash',
    'ACHIEVEMENT_CUMULATIVE_DIGITAL_PAYOUT_AMOUNT-2': 'Digital Dominance',
    'ACHIEVEMENT_CUMULATIVE_DIGITAL_PAYOUT_AMOUNT-3': 'Payment Prodigy',
    'ACHIEVEMENT_CUMULATIVE_DIGITAL_PAYOUT_AMOUNT-4': 'Technology Tycoon',
    'ACHIEVEMENT_CUMULATIVE_DIGITAL_VOUCHER_AMOUNT-1': 'Paperless',
    'ACHIEVEMENT_CUMULATIVE_DIGITAL_VOUCHER_AMOUNT-2': 'Arboreal Affection',
    'ACHIEVEMENT_CUMULATIVE_DIGITAL_VOUCHER_AMOUNT-3': 'Forest Friend',
    'ACHIEVEMENT_CUMULATIVE_DIGITAL_VOUCHER_AMOUNT-4': 'Proud Treehugger',
    'ACHIEVEMENT_HIGHEST_ITEM_COUNT-1': 'Feed me!',
    'ACHIEVEMENT_HIGHEST_ITEM_COUNT-2': 'Machine Maestro',
    'ACHIEVEMENT_HIGHEST_ITEM_COUNT-3': 'Collection Cyborg',
    'ACHIEVEMENT_HIGHEST_ITEM_COUNT-4': 'Robot Recycler',
    'ACHIEVEMENT_SESSIONS_WITHOUT_REJECT-1': 'Straight Shooter',
    'ACHIEVEMENT_SESSIONS_WITHOUT_REJECT-2': 'Field Goal',
    'ACHIEVEMENT_SESSIONS_WITHOUT_REJECT-3': 'Slam Dunk',
    'ACHIEVEMENT_SESSIONS_WITHOUT_REJECT-4': 'Hole in One',
    'ACHIEVEMENT_CUMULATIVE_DONATION_COUNT-1': 'Helping hand',
    'ACHIEVEMENT_CUMULATIVE_DONATION_COUNT-2': 'Benevolent Buddy',
    'ACHIEVEMENT_CUMULATIVE_DONATION_COUNT-3': 'Supporting star',
    'ACHIEVEMENT_CUMULATIVE_DONATION_COUNT-4': 'Philanthropic Phantom',
    'ACHIEVEMENT_CUMULATIVE_DONATION_ITEM_COUNT-1': 'Goodhearted Giver',
    'ACHIEVEMENT_CUMULATIVE_DONATION_ITEM_COUNT-2': 'Compassionate collector',
    'ACHIEVEMENT_CUMULATIVE_DONATION_ITEM_COUNT-3': 'Determined Donor',
    'ACHIEVEMENT_CUMULATIVE_DONATION_ITEM_COUNT-4': 'Charity Champion'
  }
}
