import React, { useContext, useMemo } from 'react'
import { LocationCategoryInfo } from './LocationCategoryInfo'
import { theme, intl } from '../../lib'
import { LocationsContext } from './LocationsContext'
import {
  LocationSearch_FilterOnReturnPoint,
  CollectionPointTypes_RVM_Title,
  CollectionPointTypes_DEPOT_Title,
  CollectionPointTypes_OTC_Title,
  CollectionPointTypes_DONATION_STATION_Title
} from '../../translations/messages'

const categoryTitleMap = {
  RVM: CollectionPointTypes_RVM_Title,
  DEPOT: CollectionPointTypes_DEPOT_Title,
  OTC: CollectionPointTypes_OTC_Title,
  DONATION_STATION: CollectionPointTypes_DONATION_STATION_Title
}

export const LocationFilter = () => {
  const { activeFilters, filterMarkers, locations } = useContext(LocationsContext)

  // Expensive computation, hence useMemo
  const locationCategories = useMemo(() => {
    return locations.reduce((acc: LocationCategory[], location) => {
      if (location.category && !acc.includes(location.category)) {
        acc.push(location.category)
      }
      return acc
    }, [])
  }, [locations])

  if (locationCategories.length === 0) {
    return null
  }

  return (
    <div className="flex flex-col space-y-3">
      <p className="flex items-center justify-between">
        <p>{intl.formatMessage(LocationSearch_FilterOnReturnPoint)}</p>
        <LocationCategoryInfo locationCategories={locationCategories} />
      </p>

      <ul className="radio-options">
        {locationCategories.map(category => (
          <li key={category}>
            <label htmlFor={category} className="!items-center" aria-label={`Filter by ${category}`}>
              <img src={theme.images.locationCategory[category].icon} alt={`${category} icon`} className="max-h-8" />

              <input
                id={category}
                type="radio"
                onClick={() => filterMarkers(category)}
                checked={activeFilters.includes(category)}
              />

              <span>{intl.formatMessage(categoryTitleMap[category])}</span>
            </label>
          </li>
        ))}
      </ul>
    </div>
  )
}
