import { config as returnandearn } from './returnandearn/config'
import { config as mytomra } from './mytomra/config'
import { config as cdsvicwest } from './cdsvicwest/config'
import { config as recyclerewards } from './recyclerewards/config'
import { APP_NAME } from '../environment'

const themeConfigs = { returnandearn, mytomra, cdsvicwest, recyclerewards }
const chosenTheme: typeof themeConfigs.mytomra = themeConfigs[APP_NAME.toLowerCase()]
chosenTheme.setThemeColors()

export const theme = chosenTheme
