import React from 'react'

type Props = {
  color?: string
  size?: string
}

export const SavingBankIcon = (props: Props) => (
  <svg
    height={props.size || '1.6rem'}
    viewBox="0 0 24 24"
    width={props.size || '1.6rem'}
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fill: 'none',
      stroke: props.color || 'currentColor',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeWidth: '1.5px'
    }}
  >
    <line x1="1.5" y1="23.25" x2="22.5" y2="23.25" />
    <path d="M22.5,8.25H1.5l9.689-5.762a1.5,1.5,0,0,1,1.622,0Z" />
    <polyline points="21 11.25 21 20.25 18 20.25 18 11.25" />
    <polyline points="13.5 11.25 13.5 20.25 10.5 20.25 10.5 11.25" />
    <polyline points="6 11.25 6 20.25 3 20.25 3 11.25" />
  </svg>
)
