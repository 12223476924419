import React from 'react'
import { Link } from 'react-router-dom'
import { useAchievementsQuery } from '../../hooks'
import { Loading } from '../shared'
import { theme, intl } from '../../lib'
import { Achievement } from './common/Achievement'
import { LocationIcon } from '../icons'
import {
  Common_Achievements,
  MyAchievements_NotRecycledYet,
  MyAchievements_RecycledContainers,
  FindLocation_ButtonText
} from '../../translations/messages'

const existingInTheme = (achievement: AchievementType) => {
  return Object.keys(theme.achievements).some(name => name.includes(achievement.achievementName))
}

export const MyAchievements = () => {
  const { data } = useAchievementsQuery()

  if (!data) {
    return <Loading className="centerAbsolute" />
  }

  const completedAchievements = data.allAchievements
    .filter(existingInTheme)
    .filter(({ currentLevel }) => currentLevel > 0)

  const completedAchievementLevels = completedAchievements.reduce(
    (acc, achievement) => acc + achievement.currentLevel,
    0
  )

  const totalAchievementLevels = data.allAchievements.filter(existingInTheme).reduce(acc => acc + 4, 0)

  return (
    <div className="flex flex-col justify-between p-5 max-w-mobile mx-auto min-h-[70vh]">
      <div className="flex items-center justify-around">
        <div className="flex flex-col items-center space-y-2">
          <div className="h-20 w-20" dangerouslySetInnerHTML={{ __html: theme.images.achievementContainers }} />
          <small>
            <b>{intl.formatMessage(MyAchievements_RecycledContainers)}</b>
          </small>
          <h1 className="text-3xl">{data.profile.totalRecycledContainers}</h1>
        </div>

        <div className="flex flex-col items-center space-y-2">
          <div className="h-20 w-20" dangerouslySetInnerHTML={{ __html: theme.images.achievementReward }} />
          <small>
            <b>{intl.formatMessage(Common_Achievements)}</b>
          </small>
          <h1 className="text-3xl">{`${completedAchievementLevels}/${totalAchievementLevels}`}</h1>
        </div>
      </div>

      {data.profile.totalRecycledContainers ? (
        completedAchievements.map(achievement => {
          const previousLevelAchievement = { ...achievement, currentLevel: achievement.currentLevel - 1 }

          return (
            <Link
              to={achievement.achievementName}
              key={achievement.achievementName + achievement.currentLevel}
              className="card mt-5"
            >
              <Achievement achievement={previousLevelAchievement} />
            </Link>
          )
        })
      ) : (
        <>
          <h1 className="text-center flex-1 mt-10">{intl.formatMessage(MyAchievements_NotRecycledYet)}</h1>

          <Link to="/locations" className="btn justify-between">
            <LocationIcon />
            <p>{intl.formatMessage(FindLocation_ButtonText)}</p>
            <div />
          </Link>
        </>
      )}
    </div>
  )
}
