import { insertIntoFirestore } from './firestore-service'
import { HttpError } from '@tomra/client-side-http-client'
import { logError } from '@tomra/datadog-browser-logging'
import { pushMessage } from '../components/AppMessages'
import {
  Common_UpdatePayoutFailure,
  PayoutSettings_Locked,
  Common_UpdatePayoutSuccess,
  Common_FetchError
} from '../translations/messages'
import {
  FIREBASE,
  API_HOST,
  APP_NAME,
  VIEWER_COUNTRY_STATE,
  getItemFromWebStorage,
  setItemInWebStorage,
  enrichedAuthenticatedFetchData,
  enrichedAuthenticatedFetchRaw,
  enrichedFetch,
  intl
} from '../lib'

const fiveSeconds = 5 * 1000
const tenSeconds = fiveSeconds * 2

export async function sendSignInLink(email: string, url: string, mode: 'REGISTER' | 'LOGIN') {
  return await enrichedFetch(`${API_HOST}/mytomra/v1.0/sign-in?mode=${mode}`, {
    method: 'POST',
    body: JSON.stringify({
      email,
      url
    })
  }).run()
}

export async function sendSignInLinkTimestamp(timestamp: string) {
  return await enrichedFetch(`${API_HOST}/mytomra/v1.0/sign-in/${encodeURIComponent(timestamp)}`, {
    method: 'POST'
  }).run()
}

export function fetchProfile(signal?: AbortSignal) {
  const params = new URLSearchParams(window.location.search)
  params.set('nativeAppName', APP_NAME)
  params.set('activeCountryState', VIEWER_COUNTRY_STATE)

  return enrichedAuthenticatedFetchRaw(`${API_HOST}/mytomra/v1.0/profile?${params.toString()}`, { signal })
    .run()
    .then(async response => {
      if (response.ok) {
        if (response.status === 201) await FIREBASE.auth.currentUser?.getIdToken(true)
        return response.json().then(json => json.data)
      } else {
        throw new HttpError(response.status, response.statusText, response.text())
      }
    })
    .catch(error => {
      logError(new Error('Failed to fetch profile'), error)

      pushMessage({
        formattedMessage: intl.formatMessage(Common_FetchError),
        type: 'danger',
        ttl: tenSeconds
      })
    })
}

export function fetchVouchers(signal?: AbortSignal) {
  return enrichedAuthenticatedFetchData(`${API_HOST}/mytomra/v2.0/vouchers`, { signal })
    .run()
    .catch(error => {
      logError(new Error('Failed to fetch vouchers'), error)

      pushMessage({
        formattedMessage: intl.formatMessage(Common_FetchError),
        type: 'danger',
        ttl: tenSeconds
      })

      throw error
    })
}

export function fetchPayoutMethods(signal?: AbortSignal) {
  return enrichedAuthenticatedFetchData(`${API_HOST}/mytomra/v1.0/accounting/payout`, { signal })
    .run()
    .then(
      response => response[0]?.payoutMethods || [],
      error => {
        logError(new Error('Failed to fetch payout methods'), error)

        pushMessage({
          formattedMessage: intl.formatMessage(Common_FetchError),
          type: 'danger',
          ttl: tenSeconds
        })

        throw error
      }
    )
}

export function fetchRefunds(signal?: AbortSignal) {
  return enrichedAuthenticatedFetchData(`${API_HOST}/mytomra/v1.0/refunds`, {
    signal
  })
    .run()
    .then(
      ({ refunds }) => refunds[0] || null,
      error => {
        logError(new Error('Failed to fetch refunds'), error)

        pushMessage({
          formattedMessage: intl.formatMessage(Common_FetchError),
          type: 'danger',
          ttl: tenSeconds
        })

        throw error
      }
    )
}

export function fetchActivities(pageStart?: string, signal?: AbortSignal) {
  return enrichedAuthenticatedFetchData(
    `${API_HOST}/mytomra/v1.0/refunds${pageStart ? `/activities?pageStart=${pageStart}` : ''}`,
    {
      signal
    }
  )
    .run()
    .then(
      response => response['paginatedActivities'] || response, // if no page start, then extract the paginatedActivities field to match payload of paginated fetch
      error => {
        logError(new Error('Failed to fetch activities'), error)

        pushMessage({
          formattedMessage: intl.formatMessage(Common_FetchError),
          type: 'danger',
          ttl: tenSeconds
        })

        throw error
      }
    )
}

export function fetchAchievements(signal?: AbortSignal) {
  return enrichedAuthenticatedFetchData(`${API_HOST}/mytomra-achievements/achievements`, { signal })
    .run()
    .catch(error => {
      logError(new Error('Failed to fetch achievements'), error)

      pushMessage({
        formattedMessage: intl.formatMessage(Common_FetchError),
        type: 'danger',
        ttl: tenSeconds
      })

      throw error
    })
}

export async function updatePayout(
  method: 'POST' | 'PUT' | 'DELETE',
  type: PayoutMethodType['type'],
  recipient?: string | null
) {
  const options = recipient ? { method, body: JSON.stringify({ recipient, type }) } : { method }
  const url = recipient
    ? `${API_HOST}/mytomra/v1.0/accounting/payout`
    : `${API_HOST}/mytomra/v1.0/accounting/payout/${type}`

  return enrichedAuthenticatedFetchData(url, options)
    .run()
    .then(
      data => {
        pushMessage({
          formattedMessage: intl.formatMessage(Common_UpdatePayoutSuccess),
          type: 'success',
          ttl: fiveSeconds
        })

        return data?.[0]?.payoutMethods || ''
      },
      error => {
        logError(new Error('Failed to update payout'), error)

        pushMessage({
          formattedMessage: intl.formatMessage(
            error.status === 409 ? PayoutSettings_Locked : Common_UpdatePayoutFailure
          ),
          type: 'danger',
          ttl: tenSeconds
        })

        throw error
      }
    )
}

export function retryPayment() {
  return enrichedAuthenticatedFetchData(`${API_HOST}/mytomra/v1.0/activities/retry`, {
    method: 'POST'
  })
    .run()
    .catch(error => {
      logError(new Error('Failed to retry activity', error))

      pushMessage({
        formattedMessage: intl.formatMessage(Common_FetchError),
        type: 'danger',
        ttl: tenSeconds
      })

      throw error
    })
}

export function updateMarketingsubscription(subscribeStatus: 'SUBSCRIBED' | 'UNSUBSCRIBED') {
  return enrichedAuthenticatedFetchData(
    `${API_HOST}/mytomra/v1.0/profile/marketingsubscription?subscribeStatus=${subscribeStatus}`,
    {
      method: 'POST'
    }
  )
}

export function fetchMarketingsubscription() {
  return enrichedAuthenticatedFetchData(`${API_HOST}/mytomra/v1.0/profile/marketingsubscription`)
}

export function updateUser(input: { email?: string; firstName?: string; activeCountryState?: string }) {
  return enrichedAuthenticatedFetchData(`${API_HOST}/mytomra/v1.0/user`, {
    method: 'PATCH',
    body: JSON.stringify(input)
  })
}

export function updatePassword(password: string) {
  return enrichedAuthenticatedFetchData(`${API_HOST}/mytomra/v1.0/user/credentials`, {
    method: 'PUT',
    body: JSON.stringify({ password })
  })
}

export function deleteUser() {
  return enrichedAuthenticatedFetchData(`${API_HOST}/mytomra/v1.0/user`, {
    method: 'DELETE'
  })
}

export async function acceptTermsAndConditions() {
  const TERMS_KEY = `HAS_ACCEPTED_${APP_NAME}_TERMS`
  const hasAcceptedTerms = await getItemFromWebStorage(TERMS_KEY)

  if (!hasAcceptedTerms) {
    await setItemInWebStorage(TERMS_KEY, true)
    await insertIntoFirestore({ [`${APP_NAME}_terms_and_conditions_accepted`]: Date.now() }).catch(() => {})
  }
}

export function registerBagtag(bagtag: string) {
  return enrichedAuthenticatedFetchData(`${API_HOST}/mytomra/v1.0/bag`, {
    method: 'POST',
    body: JSON.stringify({
      bagtag
    })
  }).run()
}

export function registerQrCode(code: string) {
  return enrichedAuthenticatedFetchData(`${API_HOST}/mytomra/v1.0/internal/qrcode`, {
    method: 'POST',
    body: JSON.stringify({
      code
    })
  }).run()
}

export function checkContainerEligibility(barcode: string, activeCountryState: string, isRetryAttempt = false) {
  return enrichedAuthenticatedFetchData(`${API_HOST}/mytomra/v1.0/${activeCountryState}/eligibility/${barcode}`, {
    method: 'GET'
  })
    .run()
    .catch(error => {
      // try again with 0 prefix if missing and without if not
      // see https://support.honeywellaidc.com/s/article/Can-EAN13-symbols-start-with-a-leading-zero
      return isRetryAttempt
        ? Promise.reject(error)
        : checkContainerEligibility(
            barcode.startsWith('0') ? barcode.substring(1) : `0${barcode}`,
            activeCountryState,
            true
          )
    })
}

export function fetchBadges(signal?: AbortSignal) {
  return enrichedAuthenticatedFetchData(`${API_HOST}/mytomra/v1.0/profile/badges`, { signal })
    .run()
    .catch(error => {
      logError(new Error('Failed to fetch badges'), error)

      pushMessage({
        formattedMessage: intl.formatMessage(Common_FetchError),
        type: 'danger',
        ttl: tenSeconds
      })
    })
}
