import React, { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { AppVersion } from './AppVersion'
import { DarkModePicker } from './DarkModePicker'
import { openBrowserWindow } from '../../../services'
import { theme, intl, removeItemFromWebStorage, FIREBASE } from '../../../lib'
import { Loading } from '../../shared'
import { EditIcon, PiggyBankIcon, HelpIcon, InfoIcon, LockIcon, EmailIcon, LogoutIcon } from '../../icons'
import {
  Common_ContactUs,
  Common_EditProfile,
  Common_PrivacyPolicy,
  Common_EditPayout,
  Settings_Faq,
  Common_Logout,
  Settings_Title,
  Settings_Tos
} from '../../../translations/messages'

export const Settings = () => {
  const navigate = useNavigate()
  const [loadingLogout, setLoadingLogout] = useState(false)

  const _logout = async () => {
    setLoadingLogout(true)
    await removeItemFromWebStorage('lastAuthTimestamp')
    await FIREBASE.auth.signOut()
    navigate('/')
  }

  const openDocLink = (docName: 'termsOfService' | 'privacyPolicy' | 'faq') => {
    openBrowserWindow(theme[docName])
  }

  return (
    <div className="py-16 px-5 flex flex-col space-y-10">
      <span className="flex items-center justify-between">
        <h1 className="text-3xl ml-5">{intl.formatMessage(Settings_Title)}</h1>
        <DarkModePicker />
      </span>

      <ul className="card-list" title="settings-options">
        <li className="inverted">
          <Link className="active" to="/account">
            <EditIcon />
            <span>{intl.formatMessage(Common_EditProfile)}</span>
          </Link>
        </li>

        <li className="inverted">
          <Link className="active" to="/payoutsettings">
            <PiggyBankIcon />
            <span>{intl.formatMessage(Common_EditPayout)}</span>
          </Link>
        </li>

        <li className="inverted">
          <button className="active" onClick={() => openDocLink('faq')}>
            <HelpIcon />
            <span>{intl.formatMessage(Settings_Faq)}</span>
          </button>
        </li>

        <li className="inverted">
          <button className="active" onClick={() => openDocLink('termsOfService')}>
            <InfoIcon />
            <span>{intl.formatMessage(Settings_Tos)}</span>
          </button>
        </li>

        <li className="inverted">
          <button className="active" onClick={() => openDocLink('privacyPolicy')}>
            <LockIcon />
            <span>{intl.formatMessage(Common_PrivacyPolicy)}</span>
          </button>
        </li>

        <li className="inverted">
          <Link className="active" to="/contact">
            <EmailIcon />
            <span>{intl.formatMessage(Common_ContactUs)}</span>
          </Link>
        </li>

        <li className="inverted">
          <button className="active" onClick={_logout}>
            {loadingLogout ? (
              <Loading />
            ) : (
              <>
                <LogoutIcon />
                <span>{intl.formatMessage(Common_Logout)}</span>
              </>
            )}
          </button>
        </li>
      </ul>

      <AppVersion />
    </div>
  )
}
