import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { usePayoutMethodsQuery, usePayoutMethodMutation } from '../../hooks'
import { intl } from '../../lib'
import { payoutLogoAndTranslationMap } from './payoutLogoAndTranslationMap'
import {
  Common_Yes,
  Common_EditPayout,
  PayoutSettings_QuickSelect_Confirm_Donation_Description,
  PayoutSettings_QuickSelect_Confirm_Donation_Title,
  PayoutSettings_QuickSelect_Title,
  Common_No
} from '../../translations/messages'

export const PayoutQuickSelect = () => {
  const { data: payoutMethods } = usePayoutMethodsQuery()
  const updateQuery = usePayoutMethodMutation()
  const [showConfirmDonationPayout, setConfirmDonationPayout] = useState<boolean>(false)

  const applicableMethods = payoutMethods?.filter(
    ({ type, savedConfiguration }) => savedConfiguration !== null || type === 'E_VOUCHER'
  )

  return (
    <>
      {showConfirmDonationPayout ? (
        <div className="p-10 flex flex-col space-y-10 max-w-mobile mx-auto">
          <h1 className="text-3xl">{intl.formatMessage(PayoutSettings_QuickSelect_Confirm_Donation_Title)}</h1>
          <p>{intl.formatMessage(PayoutSettings_QuickSelect_Confirm_Donation_Description)}</p>

          <div className="flex space-x-5">
            <button className="btn w-full" onClick={() => setConfirmDonationPayout(false)}>
              {intl.formatMessage(Common_No)}
            </button>

            <button
              className="btn primary w-full"
              aria-label={intl.formatMessage(Common_Yes)}
              onClick={() => {
                setConfirmDonationPayout(false)
                updateQuery.mutate({ type: 'DONATION', method: 'PUT' })
              }}
            >
              {intl.formatMessage(Common_Yes)}
            </button>
          </div>
        </div>
      ) : (
        <div className="flex flex-col space-y-5 p-5 max-w-mobile mx-auto" data-testid="PayoutQuickSelect">
          <b>{intl.formatMessage(PayoutSettings_QuickSelect_Title)}</b>

          <ul className="radio-options">
            {applicableMethods?.map(({ type, active, savedConfiguration, logoUrl }: PayoutMethodType) => {
              const { logo, name } = payoutLogoAndTranslationMap[type]
              const isLoading = updateQuery.isPending && updateQuery.variables.type === type

              return (
                <li key={type}>
                  <label htmlFor={type} className="cursor-pointer">
                    {logoUrl ? <img src={logoUrl} alt="charity logo" className="h-10" /> : logo}

                    <input
                      id={type}
                      type="radio"
                      name={type}
                      aria-disabled={active || isLoading}
                      aria-label={type}
                      disabled={active || isLoading}
                      defaultChecked={active}
                      onClick={() =>
                        type === 'DONATION'
                          ? setConfirmDonationPayout(true)
                          : updateQuery.mutate({ type, method: 'PUT' })
                      }
                    />

                    <div>
                      <p>{name}</p>
                      {savedConfiguration && (
                        <p>
                          <small className="text-sm">
                            {savedConfiguration?.email ||
                              savedConfiguration?.maskedAccountNumber ||
                              savedConfiguration?.campaignName}
                          </small>
                        </p>
                      )}
                    </div>
                  </label>
                </li>
              )
            })}
          </ul>

          <Link to="../payoutsettings" className="btn mt-10 w-full">
            {intl.formatMessage(Common_EditPayout)}
          </Link>
        </div>
      )}
    </>
  )
}
