import React, { useEffect, useState } from 'react'
import { Modal } from './shared'
import {
  getNativeNotificationToken,
  initAPNsPush,
  initLegacyPush,
  initWebPush,
  usesiOSNativeWrapper
} from '../services'
import { theme, setItemInWebStorage, getItemFromWebStorage, intl, FIREBASE } from '../lib'
import {
  NotificationPromptDrawer_Title,
  NotificationPromptDrawer_Description,
  Common_Yes,
  Common_No
} from '../translations/messages'

const isE2E = navigator.userAgent.match(/Datadog/gi) || window.Cypress

export const NotificationPromptDrawer = () => {
  const [visible, setVisible] = useState(false)

  const acceptWebPush = () => {
    initWebPush()
    setVisible(false)
  }

  const declineWebPush = () => {
    setItemInWebStorage('HAS_DECLINED_WEB_PUSH', true)
    setVisible(false)
  }

  useEffect(() => {
    const init = async () => {
      if (usesiOSNativeWrapper()) {
        return initAPNsPush()
      }

      if (getNativeNotificationToken()) {
        return initLegacyPush()
      }

      if (window.Notification) {
        const hasDeclinedDrawerOption = await getItemFromWebStorage('HAS_DECLINED_WEB_PUSH')
        const hasDeclinedBrowserPrompt = Notification?.permission === 'denied'
        const hasAcceptedBrowserPrompt = Notification?.permission === 'granted'

        if (hasDeclinedBrowserPrompt || hasDeclinedDrawerOption) return
        if (hasAcceptedBrowserPrompt) initWebPush()
        else setVisible(true)
      }
    }

    !isE2E && FIREBASE.auth.currentUser && init()
  }, [])

  return (
    <Modal onClose={() => setVisible(false)} isOpen={visible}>
      <div className="p-10">
        <div className="max-w-mobile mx-auto flex flex-col space-y-5">
          <h1 className="text-3xl">{intl.formatMessage(NotificationPromptDrawer_Title)}</h1>

          <p>{intl.formatMessage(NotificationPromptDrawer_Description, { appName: theme.name })}</p>

          <button className="btn primary" onClick={acceptWebPush}>
            {intl.formatMessage(Common_Yes)}
          </button>

          <button className="btn" onClick={declineWebPush}>
            {intl.formatMessage(Common_No)}
          </button>
        </div>
      </div>
    </Modal>
  )
}
