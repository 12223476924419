import React from 'react'

type Props = {
  color?: string
  size?: string
}

export const ArrowRightIcon = (props: Props) => (
  <svg
    height={props.size || '0.87rem'}
    viewBox="0 0 7.19 12.38"
    width={props.size || '0.87rem'}
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fill: props.color || 'currentColor'
    }}
  >
    <path d="M6.9,5.48,1.71.29A1,1,0,0,0,.29,1.71L4.78,6.19.29,10.67a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L6.9,6.9A1,1,0,0,0,6.9,5.48Z" />
  </svg>
)
