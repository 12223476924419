import { logInfo } from '@tomra/datadog-browser-logging'
import { getQueryParameterByName } from './getQueryParameterByName'

const maybeReloadPage = (reason: string) => {
  const alreadyHandled = getQueryParameterByName('indexedDbErrorRetry')

  if (!alreadyHandled) {
    if (reason.match(/Connection to Indexed Database server lost/gi)) {
      logInfo('Connection to Indexed Database server lost. Reloading page.')
      const searchParams = new URLSearchParams(window.location.search)
      searchParams.append('indexedDbErrorRetry', 'true')
      window.location.href = `${window.location.origin + window.location.pathname}?${searchParams.toString()}`
    }
  }
}

/**
 * Listen for IndexedDB errors and reload the page if the error is due to a lost connection to the IndexedDB server.
 * This will quite often in Safari when it's been in the background for a while and is then resumed.
 */
export const listenForIndexedDbErrors = () => {
  window.addEventListener('unhandledrejection', error =>
    maybeReloadPage(error?.reason?.message || JSON.stringify(error?.reason) || '')
  )

  window.addEventListener('error', error => maybeReloadPage(error?.message || ''))
}
