import React, { FormEvent, FunctionComponent, useState } from 'react'
import { logError } from '@tomra/datadog-browser-logging'
import { Modal, TextField, Loading } from '../../shared/common'
import {
  Common_Send,
  Common_Email,
  TaxReceiptDrawer_Description,
  TaxReceiptDrawer_EmailNotSent,
  TaxReceiptDrawer_EmailSent,
  TaxReceiptDrawer_From,
  TaxReceiptDrawer_FromDateMustBeBeforeToDate,
  TaxReceiptDrawer_NoValidDonations,
  TaxReceiptDrawer_Title,
  TaxReceiptDrawer_To,
  TaxReceiptDrawer_ToMustBeInPast
} from '../../../translations/messages'
import { FIREBASE, intl } from '../../../lib'
import { DatePickerField } from './DatePickerField'
import { sendTaxReceipts } from '../../../services'

const startOfYear = new Date(`${new Date().getFullYear()}-01-01`).toISOString().substr(0, 10)
const today = new Date().toISOString().substr(0, 10)

type Props = {
  show: boolean
  onClose: () => void
}

export const TaxReceiptDrawer: FunctionComponent<Props> = ({ show, onClose }) => {
  const email = FIREBASE.auth.currentUser?.email || ''
  const [requestStatus, setRequestStatus] = useState<RequestStatusType | 'noValidDonations'>('initial')
  const [receiptEmail, setReceiptEmail] = useState<string>(email)
  const [receiptEmailValid, setReceiptEmailValid] = useState<boolean>(true)
  const [fromDate, setFromDate] = useState<string>(startOfYear)
  const [toDate, setToDate] = useState<string>(today)
  const startBeforeEndDate = new Date(fromDate).valueOf() <= new Date(toDate).valueOf()
  const endBeforeOrEqualToToday = new Date(toDate).valueOf() <= new Date().valueOf()
  const datesAreValid = startBeforeEndDate && endBeforeOrEqualToToday
  const sendButtonDisabled = !receiptEmailValid || !datesAreValid || requestStatus === 'loading'

  const triggerReceipts = (event: FormEvent) => {
    event.preventDefault()
    setRequestStatus('loading')

    sendTaxReceipts(fromDate, toDate, receiptEmail)
      .then(() => {
        setRequestStatus('success')
        setTimeout(() => onClose, 3000)
      })
      .catch(error => {
        setRequestStatus(error.status === 412 ? 'noValidDonations' : 'failed')
        logError(new Error('Could not send tax receipts.'), error)
      })
  }

  return (
    <Modal isOpen={show} onClose={onClose}>
      <div className="p-10">
        <div className="max-w-mobile mx-auto space-y-5">
          <h1 className="text-3xl">{intl.formatMessage(TaxReceiptDrawer_Title)}</h1>
          <p>{intl.formatMessage(TaxReceiptDrawer_Description)}</p>

          <form onSubmit={triggerReceipts} className="space-y-5">
            <DatePickerField
              label={intl.formatMessage(TaxReceiptDrawer_From)}
              value={fromDate}
              valid={datesAreValid}
              maxDate={new Date().toISOString().substring(0, 10)}
              onChange={value => setFromDate(value)}
              required={true}
              errorMsg={!startBeforeEndDate ? intl.formatMessage(TaxReceiptDrawer_FromDateMustBeBeforeToDate) : ''}
            />

            <DatePickerField
              label={intl.formatMessage(TaxReceiptDrawer_To)}
              value={toDate}
              valid={datesAreValid}
              maxDate={new Date().toISOString().substring(0, 10)}
              onChange={value => setToDate(value)}
              required={true}
              errorMsg={!endBeforeOrEqualToToday ? intl.formatMessage(TaxReceiptDrawer_ToMustBeInPast) : ''}
            />

            <TextField
              type="email"
              label={intl.formatMessage(Common_Email)}
              value={receiptEmail}
              onChange={(value, valid) => {
                setReceiptEmailValid(valid)
                setReceiptEmail(value)
              }}
              ignoreWhitespace
              required
            />

            {requestStatus === 'success' && (
              <div className="alert success">{intl.formatMessage(TaxReceiptDrawer_EmailSent)}</div>
            )}

            {requestStatus === 'failed' && (
              <div className="alert danger">{intl.formatMessage(TaxReceiptDrawer_EmailNotSent)}</div>
            )}

            {requestStatus === 'noValidDonations' && (
              <div className="alert warning">{intl.formatMessage(TaxReceiptDrawer_NoValidDonations)}</div>
            )}

            <button className="btn primary w-full" disabled={sendButtonDisabled}>
              {requestStatus === 'loading' ? <Loading /> : intl.formatMessage(Common_Send)}
            </button>
          </form>
        </div>
      </div>
    </Modal>
  )
}
