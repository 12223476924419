import React from 'react'

type Props = {
  color?: string
  size?: string
}

export const LocationListIcon = (props: Props) => (
  <svg
    height={props.size || '1.6rem'}
    viewBox="0 0 24 24"
    width={props.size || '1.6rem'}
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fill: 'none',
      stroke: props.color || 'currentColor',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeWidth: '1.5px'
    }}
  >
    <path d="M15.75,6.761H19.5a1.5,1.5,0,0,1,1.5,1.5V18.14a1.5,1.5,0,0,1-.439,1.06l-3.622,3.622a1.5,1.5,0,0,1-1.06.439H4.5a1.5,1.5,0,0,1-1.5-1.5V8.261a1.5,1.5,0,0,1,1.5-1.5H8.25" />
    <path d="M15,23.261v-4.5a1.5,1.5,0,0,1,1.5-1.5H21" />
    <circle cx="12" cy="3.011" r="2.25" />
    <line x1="12" y1="10.511" x2="12" y2="5.261" />
    <line x1="6.75" y1="14.261" x2="17.25" y2="14.261" />
    <line x1="6.75" y1="18.761" x2="11.25" y2="18.761" />
  </svg>
)
