import React from 'react'

type Props = {
  color?: string
  size?: string
  className?: string
}

export const EnvelopeIcon = (props: Props) => (
  <svg
    className={props.className}
    height={props.size || '1.6rem'}
    viewBox="0 0 24 24"
    width={props.size || '1.6rem'}
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fill: 'none',
      stroke: props.color || 'currentColor',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeWidth: '1.5px'
    }}
  >
    <rect x="1.5" y="4.75" width="21" height="15" rx="1.5" ry="1.5" />
    <path d="M22.161,5.3l-8.144,6.264a3.308,3.308,0,0,1-4.034,0L1.839,5.3" />
  </svg>
)
