import React from 'react'

type Props = {
  color?: string
  size?: string
  className?: string
}

export const PersonQuestionIcon = (props: Props) => (
  <svg
    className={props.className}
    height={props.size || '1.6rem'}
    viewBox="0 0 24 24"
    width={props.size || '1.6rem'}
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fill: 'none',
      stroke: props.color || 'currentColor',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeWidth: '1.5px'
    }}
  >
    <path d="M.75,17.25a6.753,6.753,0,0,1,9.4-6.208" />
    <circle cx="7.5" cy="4.875" r="4.125" />
    <circle cx="17.25" cy="17.25" r="6" />
    <path d="M15.375,16.125A1.875,1.875,0,1,1,17.25,18" />
    <path d="M17.25,20.25a.375.375,0,1,0,.375.375.374.374,0,0,0-.375-.375" />
  </svg>
)
