import React, { useState, useContext } from 'react'
import { Modal } from './shared'
import { theme, intl, APP_NAME } from '../lib'
import { RemoteConfigContext } from './RemoteConfigContext'
import { satisfiesNativeAppVersion, getNativeDeviceInfo, openAppUrl } from '../services'
import {
  NativeAppUpdatedDialog_FirstDescription,
  NativeAppUpdatedDialog_SecondDescription,
  NativeAppUpdatedDialog_Title
} from '../translations/messages'

type NativeAppVersionsType = {
  ios: string
  android: string
}

export const NativeAppUpdatedDrawer = () => {
  const { latestNativeAppVersionJson } = useContext(RemoteConfigContext)
  const latestNativeAppVersionForApp: NativeAppVersionsType = JSON.parse(latestNativeAppVersionJson)[APP_NAME]
  const isOnLatest = latestNativeAppVersionForApp ? satisfiesNativeAppVersion(latestNativeAppVersionForApp) : true
  const [visible, setVisible] = useState(isOnLatest !== undefined && !isOnLatest)
  const OS = getNativeDeviceInfo()?.OS
  const storeName = OS === 'IOS' ? 'App Store' : 'Google Play'

  const openAppOrPlayStore = () => {
    if (OS === 'IOS') {
      openAppUrl(theme.appLinks.ios)
    } else {
      openAppUrl(theme.appLinks.android)
    }

    setVisible(false)
  }

  return (
    <Modal onClose={() => setVisible(false)} isOpen={visible}>
      <div className="space-y-5 p-10 flex flex-col">
        <h1 className="text-3xl">{intl.formatMessage(NativeAppUpdatedDialog_Title)}</h1>

        <p>{intl.formatMessage(NativeAppUpdatedDialog_FirstDescription, { appName: theme.name })}</p>

        <p>{intl.formatMessage(NativeAppUpdatedDialog_SecondDescription)}</p>

        <button className="btn-icon self-center" onClick={openAppOrPlayStore} aria-label={storeName}>
          <img
            src={OS === 'IOS' ? theme.images.appStoreLogo : theme.images.playStoreLogo}
            alt={storeName}
            width="170px"
          />
        </button>
      </div>
    </Modal>
  )
}
