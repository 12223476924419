import { countryStateMap } from './countryStateMap'

export const resolveCountryStateEnv = () => {
  const { APP_NAME, ENV, VIEWER_COUNTRY, VIEWER_STATE } = window.__ENVIRONMENT__

  if (APP_NAME === 'RETURNANDEARN') {
    return {
      VIEWER_COUNTRY_STATE: 'AU-NSW',
      ENV: 'au'
    }
  }

  if (APP_NAME === 'CDSVICWEST') {
    return {
      VIEWER_COUNTRY_STATE: 'AU-VIC',
      ENV: 'au'
    }
  }

  if (APP_NAME === 'RECYCLEREWARDS') {
    return {
      VIEWER_COUNTRY_STATE: 'AU-TAS',
      ENV: 'au'
    }
  }

  if (countryStateMap[`${VIEWER_COUNTRY}-${VIEWER_STATE}`]) {
    return {
      VIEWER_COUNTRY_STATE: `${VIEWER_COUNTRY}-${VIEWER_STATE}`,
      ENV
    }
  }

  if (countryStateMap[VIEWER_COUNTRY]) {
    return {
      VIEWER_COUNTRY_STATE: VIEWER_COUNTRY,
      ENV
    }
  }

  const fallbackCountryState = Object.keys(countryStateMap)[0]

  return {
    VIEWER_COUNTRY_STATE: fallbackCountryState,
    ENV: countryStateMap[fallbackCountryState].env
  }
}
