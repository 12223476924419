import defaultBadge from './achievements/badges/default.png'
import unknownBadge from './achievements/badges/unknown.svg'
import bottles1 from './achievements/badges/bottlesDonated1.svg'
import bottles2 from './achievements/badges/bottlesDonated2.svg'
import bottles3 from './achievements/badges/bottlesDonated3.svg'
import bottles4 from './achievements/badges/bottlesDonated4.svg'
import trophy1 from './achievements/badges/trophy1.svg'
import trophy2 from './achievements/badges/trophy2.svg'
import trophy3 from './achievements/badges/trophy3.svg'
import trophy4 from './achievements/badges/trophy4.svg'
import digitalPayout1 from './achievements/badges/digitalPayout1.svg'
import digitalPayout2 from './achievements/badges/digitalPayout2.svg'
import digitalPayout3 from './achievements/badges/digitalPayout3.svg'
import digitalPayout4 from './achievements/badges/digitalPayout4.svg'
import loop1 from './achievements/badges/loop1.svg'
import loop2 from './achievements/badges/loop2.svg'
import loop3 from './achievements/badges/loop3.svg'
import loop4 from './achievements/badges/loop4.svg'
import locations1 from './achievements/badges/locations1.svg'
import locations2 from './achievements/badges/locations2.svg'
import locations3 from './achievements/badges/locations3.svg'
import locations4 from './achievements/badges/locations4.svg'
import machine1 from './achievements/badges/machine1.svg'
import machine2 from './achievements/badges/machine2.svg'
import machine3 from './achievements/badges/machine3.svg'
import machine4 from './achievements/badges/machine4.svg'
import tree1 from './achievements/badges/tree1.svg'
import tree2 from './achievements/badges/tree2.svg'
import tree3 from './achievements/badges/tree3.svg'
import tree4 from './achievements/badges/tree4.svg'
import timesDonated1 from './achievements/badges/timesDonated1.svg'
import timesDonated2 from './achievements/badges/timesDonated2.svg'
import timesDonated3 from './achievements/badges/timesDonated3.svg'
import timesDonated4 from './achievements/badges/timesDonated4.svg'
import achievementContainers from './achievements/achievementContainers.svg?raw'
import achievementReward from './achievements/achievementReward.svg?raw'
import welcomePageBrandingElement from './logos/welcomePageBrandingElement.svg'
import welcomePageLogo from './logos/welcomePageLogo.svg'
import welcomePageLogoLight from './logos/welcomePageLogoLight.svg'
import rvmMapPin from './map/rvmMapPin.svg'
import otcMapPin from './map/otcMapPin.svg'
import depotMapPin from './map/depotMapPin.svg'
import donationStationMapPin from './map/donationStationMapPin.svg'
import rvmMapIcon from './map/rvmMapIcon.svg'
import otcMapIcon from './map/otcMapIcon.svg'
import depotMapIcon from './map/depotMapIcon.svg'
import donationStationMapIcon from './map/donationStationMapIcon.svg'
import campaign from './misc/campaign.jpg'

export const cdsvicwestImages = {
  welcomePageBrandingElement,
  welcomePageLogo,
  welcomePageLogoLight,
  achievementContainers,
  achievementReward,
  campaign,
  locationCategory: {
    RVM: {
      pin: rvmMapPin,
      icon: rvmMapIcon
    },
    DEPOT: {
      pin: depotMapPin,
      icon: depotMapIcon
    },
    OTC: {
      pin: otcMapPin,
      icon: otcMapIcon
    },
    DONATION_STATION: {
      pin: donationStationMapPin,
      icon: donationStationMapIcon
    }
  },
  achievements: {
    'ACHIEVEMENT_CUMULATIVE_ITEM_COUNT-1': trophy1,
    'ACHIEVEMENT_CUMULATIVE_ITEM_COUNT-2': trophy2,
    'ACHIEVEMENT_CUMULATIVE_ITEM_COUNT-3': trophy3,
    'ACHIEVEMENT_CUMULATIVE_ITEM_COUNT-4': trophy4,
    'ACHIEVEMENT_CUMULATIVE_DIGITAL_PAYOUT_AMOUNT-1': digitalPayout1,
    'ACHIEVEMENT_CUMULATIVE_DIGITAL_PAYOUT_AMOUNT-2': digitalPayout2,
    'ACHIEVEMENT_CUMULATIVE_DIGITAL_PAYOUT_AMOUNT-3': digitalPayout3,
    'ACHIEVEMENT_CUMULATIVE_DIGITAL_PAYOUT_AMOUNT-4': digitalPayout4,
    'ACHIEVEMENT_CUMULATIVE_SESSION_COUNT-1': loop1,
    'ACHIEVEMENT_CUMULATIVE_SESSION_COUNT-2': loop2,
    'ACHIEVEMENT_CUMULATIVE_SESSION_COUNT-3': loop3,
    'ACHIEVEMENT_CUMULATIVE_SESSION_COUNT-4': loop4,
    'ACHIEVEMENT_UNIQUE_LOCATION_COUNT-1': locations1,
    'ACHIEVEMENT_UNIQUE_LOCATION_COUNT-2': locations2,
    'ACHIEVEMENT_UNIQUE_LOCATION_COUNT-3': locations3,
    'ACHIEVEMENT_UNIQUE_LOCATION_COUNT-4': locations4,
    'ACHIEVEMENT_HIGHEST_ITEM_COUNT-1': machine1,
    'ACHIEVEMENT_HIGHEST_ITEM_COUNT-2': machine2,
    'ACHIEVEMENT_HIGHEST_ITEM_COUNT-3': machine3,
    'ACHIEVEMENT_HIGHEST_ITEM_COUNT-4': machine4,
    'ACHIEVEMENT_CUMULATIVE_DIGITAL_VOUCHER_AMOUNT-1': tree1,
    'ACHIEVEMENT_CUMULATIVE_DIGITAL_VOUCHER_AMOUNT-2': tree2,
    'ACHIEVEMENT_CUMULATIVE_DIGITAL_VOUCHER_AMOUNT-3': tree3,
    'ACHIEVEMENT_CUMULATIVE_DIGITAL_VOUCHER_AMOUNT-4': tree4,
    'ACHIEVEMENT_CUMULATIVE_DONATION_COUNT-1': timesDonated1,
    'ACHIEVEMENT_CUMULATIVE_DONATION_COUNT-2': timesDonated2,
    'ACHIEVEMENT_CUMULATIVE_DONATION_COUNT-3': timesDonated3,
    'ACHIEVEMENT_CUMULATIVE_DONATION_COUNT-4': timesDonated4,
    'ACHIEVEMENT_CUMULATIVE_DONATION_ITEM_COUNT-1': bottles1,
    'ACHIEVEMENT_CUMULATIVE_DONATION_ITEM_COUNT-2': bottles2,
    'ACHIEVEMENT_CUMULATIVE_DONATION_ITEM_COUNT-3': bottles3,
    'ACHIEVEMENT_CUMULATIVE_DONATION_ITEM_COUNT-4': bottles4,
    defaultBadge,
    unknownBadge
  }
}
