import React from 'react'

type Props = {
  color?: string
  size?: string
}

export const MenuIcon = (props: Props) => (
  <svg
    width={props.size || '1.6rem'}
    height={props.size || '1.6rem'}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 9.5C14.6193 9.5 13.5 8.38071 13.5 7C13.5 5.61929 14.6193 4.5 16 4.5C17.3807 4.5 18.5 5.61929 18.5 7C18.5 8.38071 17.3807 9.5 16 9.5ZM16 18.5C14.6193 18.5 13.5 17.3807 13.5 16C13.5 14.6193 14.6193 13.5 16 13.5C17.3807 13.5 18.5 14.6193 18.5 16C18.5 17.3807 17.3807 18.5 16 18.5ZM13.5 25C13.5 26.3807 14.6193 27.5 16 27.5C17.3807 27.5 18.5 26.3807 18.5 25C18.5 23.6193 17.3807 22.5 16 22.5C14.6193 22.5 13.5 23.6193 13.5 25Z"
      fill={props.color || 'currentColor'}
    />
  </svg>
)
