import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRefundsQuery } from '../../hooks'
import { intl } from '../../lib'
import { BackButton, Modal, Loading } from '../shared'
import { ConfirmDelete } from './ConfirmDelete'
import {
  Common_Cancel,
  Common_DeleteAccount,
  DeleteAccount_ListOne_ItemOne,
  DeleteAccount_ListOne_ItemThree,
  DeleteAccount_ListOne_ItemTwo,
  DeleteAccount_ListOne_Title,
  DeleteAccount_ListTwo_ItemOne,
  DeleteAccount_ListTwo_Title,
  DeleteAccount_PermanenceDisclaimerLabel,
  DeleteAccount_RefundsDisclaimerLabel,
  DeleteAccount_RemainingRefundsWarning
} from '../../translations/messages'

export const DeleteAccount = () => {
  const { data: refunds, isLoading } = useRefundsQuery()
  const navigate = useNavigate()
  const [checkboxAccepted, setCheckboxAccepted] = useState(false)
  const [showConfirmDeleteDrawer, setShowConfirmDeleteDrawer] = useState(false)

  if (isLoading) {
    return <Loading className="centerAbsolute" />
  }

  const balanceSum = refunds?.balanceStatistics?.totalAmount || 0
  const hasRefunds = balanceSum !== 0
  const currency = refunds?.currency

  return (
    <>
      {showConfirmDeleteDrawer && (
        <Modal isOpen={showConfirmDeleteDrawer} onClose={() => setShowConfirmDeleteDrawer(false)}>
          <ConfirmDelete onClose={() => setShowConfirmDeleteDrawer(false)} />
        </Modal>
      )}

      <div className="flex flex-col p-5 mx-auto max-w-mobile space-y-8 mt-5">
        <BackButton />

        <h1 className="text-3xl">{intl.formatMessage(Common_DeleteAccount)}</h1>

        {hasRefunds && (
          <div className="alert danger">
            {intl.formatMessage(DeleteAccount_RemainingRefundsWarning, {
              outstandingBalance: intl
                .formatNumber(balanceSum, {
                  style: 'currency',
                  currencyDisplay: 'symbol',
                  currency: currency || 'AUD'
                })
                .replace(/^.*(?=(\$))/, '')
            })}
          </div>
        )}

        <div>
          <b>{intl.formatMessage(DeleteAccount_ListOne_Title)}</b>
          <ul className="list-inside list-disc">
            <li>{intl.formatMessage(DeleteAccount_ListOne_ItemOne)}</li>
            <li>{intl.formatMessage(DeleteAccount_ListOne_ItemTwo)}</li>
            <li>{intl.formatMessage(DeleteAccount_ListOne_ItemThree)}</li>
          </ul>
        </div>

        <div>
          <b>{intl.formatMessage(DeleteAccount_ListTwo_Title)}</b>
          <ul className="list-inside list-disc">
            <li>{intl.formatMessage(DeleteAccount_ListTwo_ItemOne)}</li>
          </ul>
        </div>

        <div className="flex space-x-3 flex-start">
          <input
            id="delete-account-disclaimer"
            type="checkbox"
            className="checkbox min-w-[1.3rem]"
            onChange={() => setCheckboxAccepted(!checkboxAccepted)}
            checked={checkboxAccepted}
          />
          {hasRefunds ? (
            <label htmlFor="delete-account-disclaimer">
              {intl.formatMessage(DeleteAccount_RefundsDisclaimerLabel)}
            </label>
          ) : (
            <label htmlFor="delete-account-disclaimer">
              {intl.formatMessage(DeleteAccount_PermanenceDisclaimerLabel)}
            </label>
          )}
        </div>

        <div className="flex items-center justify-evenly space-x-3">
          <button className="btn flex-1" onClick={() => navigate(-1)}>
            {intl.formatMessage(Common_Cancel)}
          </button>
          <button
            className="btn danger flex-1 disabled:opacity-60 disabled:cursor-not-allowed"
            onClick={() => setShowConfirmDeleteDrawer(true)}
            disabled={!checkboxAccepted}
          >
            {intl.formatMessage(Common_DeleteAccount)}
          </button>
        </div>
      </div>
    </>
  )
}
