import React from 'react'
import { getNativeDeviceInfo } from '../../../services'

export const NswInMytomraBanner = () => {
  const getAppUrl = () => {
    const OS = getNativeDeviceInfo()?.OS
    return OS === 'IOS'
      ? 'https://itunes.apple.com/au/app/id1574701034'
      : 'http://play.google.com/store/apps/details?id=com.tomra.digital.returnandearn'
  }

  return (
    <div className="card flex flex-col p-5 space-y-5 bg-yellow dark:bg-yellow/30">
      <h2 className="text-xl">Please note!</h2>
      <p>The myTOMRA app can not be used in New South Wales anymore. Please download Return and Earn here:</p>
      <button className="btn" onClick={() => window.open(getAppUrl())}>
        Download
      </button>
    </div>
  )
}
