import React from 'react'
import { CharityDonationCard } from './common'
import {
  MyDonationsPage_NotDonatedYet,
  MyDonationsPage_PreviousDonations,
  MyDonationsPage_YouHaveDonated,
  Common_More
} from '../../translations/messages'
import { theme, intl } from '../../lib'
import { Currency, Loading } from '../shared'
import { useDonationsSumQuery, useDonationsQuery } from '../../hooks'

export const MyDonationsPage = () => {
  const { data: totalDonationSum } = useDonationsSumQuery()
  const { data: donations, fetchNextPage, hasNextPage, isFetchingNextPage } = useDonationsQuery()

  if (!donations) {
    return (
      <div className="mt-5 space-y-5 max-w-mobile mx-auto px-5">
        {[1, 2, 3].map((_, i) => (
          <div key={i} className="flex items-center space-x-5 card p-5 w-full">
            <div className="skeleton-circle min-w-16 min-h-16"></div>

            <div className="space-y-4 flex flex-col">
              <div className="skeleton-heading w-32"></div>
              <div className="skeleton-paragraph w-20"></div>
              <div className="skeleton-paragraph w-16"></div>
            </div>
          </div>
        ))}
      </div>
    )
  }

  const compoundedDonations = donations.pages.map(page => page.donations).flat()

  return (
    <div className="flex flex-col p-5 max-w-mobile mx-auto space-y-10">
      <div className="flex items-center justify-center space-x-5 mt-5">
        <img src={theme.images.donationHeart} alt="A heart with hands holding a dollar bill" className="max-w-32" />

        <div>
          <small className="mb-2 block font-bold">{intl.formatMessage(MyDonationsPage_YouHaveDonated)}</small>

          <h1 className="text-3xl">
            <Currency amount={totalDonationSum || 0} currency={'AUD'} />
          </h1>
        </div>
      </div>

      {compoundedDonations.length > 0 ? (
        <div>
          <small className="font-bold block mb-2">{intl.formatMessage(MyDonationsPage_PreviousDonations)}</small>

          <div className="flex flex-col space-y-5">
            <>
              {compoundedDonations.map((donation, i) => (
                <CharityDonationCard donation={donation} key={`don-${i}`} />
              ))}

              {hasNextPage && (
                <button className="btn primary my-5" onClick={() => fetchNextPage()}>
                  {isFetchingNextPage ? <Loading /> : intl.formatMessage(Common_More)}
                </button>
              )}
            </>
          </div>
        </div>
      ) : (
        <div className="alert info">{intl.formatMessage(MyDonationsPage_NotDonatedYet)}</div>
      )}
    </div>
  )
}
