import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { LocationsContext } from './LocationsContext'
import { InfoIcon } from '../icons'
import { intl } from '../../lib'
import { LocationList_Title, LocationList_Subtitle, LocationList_NoLocations } from '../../translations/messages'

export const LocationList = () => {
  const { getLocationsInViewport } = useContext(LocationsContext)
  const numberOfLocations = getLocationsInViewport().length

  return (
    <form className="flex flex-col py-10 px-5 max-w-mobile mx-auto">
      <h1 className="text-3xl">{intl.formatMessage(LocationList_Title)}</h1>

      {numberOfLocations > 0 ? (
        <span className="text-teal-dark dark:text-teal-light">
          {intl.formatMessage(LocationList_Subtitle, { numberOfLocations })}
        </span>
      ) : (
        <span className="text-warm-red-dark dark:text-warm-red-light">
          {intl.formatMessage(LocationList_NoLocations, { numberOfResults: 0 })}
        </span>
      )}

      <div className="flex flex-col overflow-scroll max-h-[70vh] mt-10">
        {getLocationsInViewport().map(({ id, name, address, readableOpeningHours }) => (
          <div key={id} className="relative text-sm py-3 border-t border-dust">
            <div className="max-w-80">
              <h2 className="truncate text-lg font-bold mb-3">{name}</h2>
              <p>{address}</p>
              <p>{readableOpeningHours}</p>
            </div>

            <Link
              to={id}
              aria-label="details"
              className="btn-icon rounded-full bg-white dark:bg-black absolute top-1/2 right-0 -translate-y-1/2"
            >
              <InfoIcon size="35" />
            </Link>
          </div>
        ))}
      </div>
    </form>
  )
}
