import React, { useState } from 'react'
import { intl } from '../../lib'
import { BackButton, Loading } from '../shared'
import { useVouchersQuery } from '../../hooks'
import { VoucherSwiper } from './VoucherSwiper'
import {
  VoucherPage_Archived,
  VoucherPage_Available,
  VoucherPage_NoVouchers,
  Common_Vouchers
} from '../../translations/messages'

export const VoucherPage = () => {
  const { data } = useVouchersQuery()
  const [activeTab, setActiveTab] = useState<'unredeemed' | 'archived'>('unredeemed')

  if (!data) {
    return <Loading className="centerAbsolute" />
  }

  const { unredeemed, archived } = data
  const hasNoVouchers = activeTab === 'archived' ? archived.length === 0 : unredeemed.length === 0

  return (
    <div className="max-w-mobile mx-auto animate-fadeIn">
      <BackButton to="/" />

      <div className="p-10 pt-16">
        <h1 className="text-3xl mb-10">{intl.formatMessage(Common_Vouchers)}</h1>

        <div className="tabs card">
          <button onClick={() => setActiveTab('unredeemed')} className={activeTab === 'unredeemed' ? 'active' : ''}>
            {intl.formatMessage(VoucherPage_Available, { numOfVouchers: unredeemed.length })}
          </button>
          <button onClick={() => setActiveTab('archived')} className={activeTab === 'archived' ? 'active' : ''}>
            {intl.formatMessage(VoucherPage_Archived, { numOfVouchers: archived?.length })}
          </button>
        </div>
      </div>

      {hasNoVouchers ? (
        <h1 className="centerAbsolute text-3xl text-center dark:text-rock text-storm">
          {intl.formatMessage(VoucherPage_NoVouchers)}
        </h1>
      ) : (
        <VoucherSwiper vouchers={activeTab === 'archived' ? archived : unredeemed} />
      )}
    </div>
  )
}
