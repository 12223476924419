import React from 'react'
import { logError } from '@tomra/datadog-browser-logging'
import { CameraPermissionHelp } from './CameraPermissionHelp'
import { ErrorIcon, CameraBorderIcon } from '../icons'

type Props = {
  description: string
  onRead: (value: string) => void
}

type State = {
  errorMsg: string
}

export class CameraScanner extends React.Component<Props, State> {
  _intervalRef = 0
  _stream?: MediaStream
  _reader?: any
  _videoElement?: HTMLVideoElement

  state: State = {
    errorMsg: ''
  }

  _attemptReadBarcode = async () => {
    try {
      const result = await this._reader.detect(this._videoElement)
      const maybeBarcode = result?.[0]?.rawValue

      // Perform basic saniation check (no special characters except dash)
      if (maybeBarcode && !!maybeBarcode.match(/^[a-zA-Z0-9-]{2,}$/gi)) {
        this.props.onRead(maybeBarcode)
      }
    } catch (error: any) {
      this.setState({ errorMsg: error.message })
      logError(new Error('Failed to read barcode'), error)
    }
  }

  _setupReader = () => {
    this._reader = new window.BarcodeDetector()

    this._intervalRef = window.setInterval(() => {
      this._attemptReadBarcode()
    }, 500)
  }

  _setupPolyfill = async () => {
    if (window.BarcodeDetector) return

    try {
      if (!window.WebAssembly) throw new Error('No WebAssembly support')

      const polyfill = await import('@undecaf/barcode-detector-polyfill')
      if (!polyfill) throw new Error('Runtime import failed')

      window.BarcodeDetector = polyfill.BarcodeDetectorPolyfill
    } catch (error: any) {
      logError(new Error('Failed to polyfill barcode detector'), error)
    }
  }

  _setupCamera = async () => {
    try {
      if (!window.BarcodeDetector || !navigator.mediaDevices?.getUserMedia) {
        throw new Error(
          "So sorry, your device doesn't support camera scanning at the moment. Please check for a system update."
        )
      }

      this._stream = await navigator.mediaDevices.getUserMedia({
        // @ts-ignore
        video: { facingMode: 'environment', focusMode: 'continuous' },
        audio: false
      })

      const videoElement = document.getElementById('cameraScanner') as HTMLVideoElement

      if (videoElement) {
        this._videoElement = videoElement
        this._videoElement.srcObject = this._stream
        this._videoElement.addEventListener('canplay', this._setupReader)
      } else {
        // Stop tracks if we unmount before stream is ready
        this._stream?.getTracks()?.forEach(track => track?.stop())
      }
    } catch (error: any) {
      this.setState({ errorMsg: error.message })
      logError(new Error('Failed to setup camera'), error)
    }
  }

  async componentDidMount() {
    await this._setupPolyfill()
    this._setupCamera()
  }

  componentWillUnmount() {
    clearInterval(this._intervalRef)
    this._videoElement?.removeEventListener('canplay', this._setupReader)
    this._stream?.getTracks()?.forEach(track => track?.stop())
  }

  render() {
    return this.state.errorMsg ? (
      <div className="h-[80vh] w-full bg-yellow flex flex-col items-center justify-center space-y-10 p-5 text-center">
        <ErrorIcon size="100" />
        <p>{this.state.errorMsg}</p>
        <div className="!mb-32">
          <CameraPermissionHelp />
        </div>
      </div>
    ) : (
      <>
        <p className="bg-bg-color dark:bg-black text-center p-3 text-sm">{this.props.description}</p>
        <video autoPlay muted playsInline id="cameraScanner" className="object-cover h-[80vh] w-full" />
        <CameraBorderIcon
          size="100vw"
          style={{ maxHeight: '70vh', opacity: 0.7 }}
          color="white"
          className="centerAbsolute"
        />
      </>
    )
  }
}
