import React, { useEffect, useState } from 'react'
import { useRouteError } from 'react-router-dom'
import { logInfo } from '@tomra/datadog-browser-logging'
import { ErrorIcon, ConnectionDownIcon } from './icons'
import { Common_GeneralError, Common_FetchError, AppUpdatedDialog_Button } from '../translations/messages'
import { Loading } from './shared'
import { intl } from '../lib'
import { type FallbackProps } from 'react-error-boundary'

const ErrorFallback = ({ errorMsg, onReset }: { errorMsg?: string; onReset: () => void }) => {
  const [isLoading, setIsLoading] = useState(false)

  const onResetButton = () => {
    setIsLoading(true)
    onReset()
  }

  return (
    <div className="h-screen bg-yellow dark:bg-yellow/30 p-10 text-center">
      <div className="max-w-mobile mx-auto flex flex-col items-center justify-around h-full">
        {errorMsg?.match(/(XHR error)|(failed to fetch)|(fetch error)|(aborted)|(request failed)|(load failed)/gi) ? (
          <>
            <ConnectionDownIcon size="100" />
            <p>{intl.formatMessage(Common_FetchError)}</p>
          </>
        ) : (
          <>
            <ErrorIcon size="100" />
            <p>{intl.formatMessage(Common_GeneralError)}</p>
            <p className="p-5 rounded-lg bg-yellow-dark/30 w-full text-sm">{errorMsg}</p>
          </>
        )}
        <button className="btn w-full" onClick={onResetButton}>
          {isLoading ? <Loading /> : intl.formatMessage(AppUpdatedDialog_Button)}
        </button>
      </div>
    </div>
  )
}

export const onGeneralErrorBoundary = error => {
  const errorMsg = typeof error === 'string' ? error : error?.message
  logInfo('General Error Boundary shown', { errorMsg })
}

export const GeneralErrorBoundary = ({ error, resetErrorBoundary }: FallbackProps) => {
  const errorMsg = typeof error === 'string' ? error : error?.message
  return <ErrorFallback errorMsg={errorMsg} onReset={resetErrorBoundary} />
}

export const RouterErrorBoundary = () => {
  const error = useRouteError() as any

  let errorMsg = ''

  if (typeof error === 'string') {
    errorMsg = error
  } else if (error?.message) {
    errorMsg = error.message
  } else if (error?.statusText) {
    errorMsg = error.statusText
  } else {
    errorMsg = 'Unknown error'
  }

  useEffect(() => {
    logInfo('Router Error Boundary shown', { error, errorMsg })
  }, [error, errorMsg])

  return <ErrorFallback errorMsg={errorMsg} onReset={() => (window.location.href = '/')} />
}
