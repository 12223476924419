import React from 'react'
import { Loading } from '../shared'
import { intl } from '../../lib'
import { activitiesQueryKey, badgesQueryKey, useBadgesQuery, useRetryActivityMutation } from '../../hooks'
import {
  Activities_FailingRefunds,
  Activities_RetryCountDown,
  Activities_RetryFailedPayments
} from '../../translations/messages'
import { useQueryClient } from '@tanstack/react-query'
import useCountdown from '../../hooks/useCountDown'

const SECONDS_TO_INVALIDATE_QUERIES = 15

export const RetryRefundsButton = () => {
  const queryClient = useQueryClient()
  const { data: badges } = useBadgesQuery()

  const { secondsLeft, startCountdown } = useCountdown(SECONDS_TO_INVALIDATE_QUERIES, () => {
    queryClient.invalidateQueries({ queryKey: activitiesQueryKey })
    queryClient.invalidateQueries({ queryKey: badgesQueryKey })
  })
  const retryActivityQuery = useRetryActivityMutation(startCountdown)

  const hasPayoutError = badges && badges.hasPayoutError

  return hasPayoutError ? (
    <>
      {secondsLeft > 0 ? (
        <div className="alert warning">{intl.formatMessage(Activities_RetryCountDown, { secondsLeft })}</div>
      ) : (
        <>
          {retryActivityQuery.status !== 'error' && (
            <div className="alert info mb-4">{intl.formatMessage(Activities_FailingRefunds)}</div>
          )}
          <button className="btn primary w-full" onClick={() => retryActivityQuery.mutate()}>
            {retryActivityQuery.isPending ? <Loading /> : intl.formatMessage(Activities_RetryFailedPayments)}
          </button>
        </>
      )}
    </>
  ) : null
}
