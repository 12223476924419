import React from 'react'
import { Link } from 'react-router-dom'
import { theme, APP_NAME } from '../../../lib'
import { isBefore, isAfter, startOfDay } from 'date-fns'

const isAfterStartTimeMap = {
  RETURNANDEARN: isAfter(new Date(), startOfDay(new Date('2024-10-20'))),
  CDSVICWEST: isAfter(new Date(), startOfDay(new Date('2024-11-11')))
}

const isBeforeEndTimeMap = {
  RETURNANDEARN: isBefore(new Date(), startOfDay(new Date('2025-04-20'))),
  CDSVICWEST: isBefore(new Date(), startOfDay(new Date('2025-11-11')))
}

export const AustraliaCampaignCard = () => {
  const isWithinTimeframe = isAfterStartTimeMap[APP_NAME] && isBeforeEndTimeMap[APP_NAME]

  if (APP_NAME === 'RETURNANDEARN' && isWithinTimeframe) {
    return (
      <Link to="/charities/1de9e5bc-1c9e-449f-aea5-5c8ffd4fe90e">
        <img
          className="cursor-pointer card"
          src={theme.images.campaign}
          alt="Every 5 Bottles or Cans you DONATE Creates 1 Meal"
        />
      </Link>
    )
  }

  if (APP_NAME === 'CDSVICWEST' && isWithinTimeframe) {
    return (
      <Link to="/charities/0634e2fa-280b-4a90-9ea0-90c7793de042">
        <img className="cursor-pointer card" src={theme.images.campaign} alt="Donate to Fight Animal Cruelty" />
      </Link>
    )
  }

  return null
}
