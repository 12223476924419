import React from 'react'

type Props = {
  color?: string
  size?: string
  className?: string
}

export const LanguageIcon = (props: Props) => (
  <svg
    height={props.size || '1.6rem'}
    viewBox="0 0 24 24"
    width={props.size || '1.6rem'}
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
    style={{
      fill: 'none',
      stroke: props.color || 'currentColor',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeWidth: '1.5px'
    }}
  >
    <polyline points="19.652 0.748 15.902 2.998 18.152 6.748" />
    <path d="M23.25,8.187A6.749,6.749,0,0,0,16.366,3.77" />
    <polyline points="4.348 23.248 8.098 20.998 5.848 17.248" />
    <path d="M.75,15.808a6.749,6.749,0,0,0,6.884,4.417" />
    <rect x="0.75" y="0.748" width="12" height="12" rx="1.5" ry="1.5" />
    <path d="M15.75,11.248h6a1.5,1.5,0,0,1,1.5,1.5v9a1.5,1.5,0,0,1-1.5,1.5h-9a1.5,1.5,0,0,1-1.5-1.5v-6" />
    <path d="M15.75,20.248v-4.5a1.5,1.5,0,0,1,3,0v4.5" />
    <line x1="15.75" y1="18.748" x2="18.75" y2="18.748" />
    <line x1="6.75" y1="3.748" x2="6.75" y2="5.248" />
    <line x1="3.75" y1="5.248" x2="9.75" y2="5.248" />
    <path d="M8.25,5.248s-1.5,4.5-4.5,4.5" />
    <path d="M6.749,8.014a3.933,3.933,0,0,0,3,1.734" />
  </svg>
)
