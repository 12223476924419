import React, { useState } from 'react'
import { Loading } from '../common'
import { ArrowRightIcon } from '../../icons'
import { countryStateMap, intl, VIEWER_COUNTRY_STATE, STAGE } from '../../../lib'
import {
  CountryStatePicker_Title,
  CountryStatePicker_Description,
  CountryStatePicker_Location,
  Common_Confirm
} from '../../../translations/messages'

export const CountryStatePicker = () => {
  const [loading, setLoading] = useState(false)
  const [selectedCountryStateCode, setSelectedCountryStateCode] = useState(VIEWER_COUNTRY_STATE)

  const onClick = () => {
    setLoading(true)
    const [country, state] = selectedCountryStateCode.split('-')

    window.localStorage.setItem('VIEWER_COUNTRY_OVERRIDE', country)
    state
      ? window.localStorage.setItem('VIEWER_STATE_OVERRIDE', state)
      : window.localStorage.removeItem('VIEWER_STATE_OVERRIDE')
    window.localStorage.setItem('ENV_OVERRIDE', countryStateMap[selectedCountryStateCode].env)

    window.location.href = '/'
  }

  return (
    <div className="flex flex-col space-y-10 p-10 max-w-mobile mx-auto">
      <h1 className="text-3xl">{intl.formatMessage(CountryStatePicker_Title)}</h1>

      <p>{intl.formatMessage(CountryStatePicker_Description)}</p>

      <div className="flex flex-col space-y-2">
        <label className="ml-5 font-bold text-sm" htmlFor="country">
          {intl.formatMessage(CountryStatePicker_Location)}
        </label>

        <select
          id="country"
          className="btn text-sm appearance-none"
          value={selectedCountryStateCode}
          onChange={evt => setSelectedCountryStateCode(evt.target.value)}
        >
          {Object.keys(countryStateMap)
            .sort()
            .map(
              countryStateCode =>
                countryStateMap[countryStateCode].selectable[STAGE] && (
                  <option key={countryStateCode} value={countryStateCode}>
                    {countryStateMap[countryStateCode].name}
                  </option>
                )
            )}
        </select>
      </div>

      <button
        className="btn primary flex items-center justify-between"
        onClick={onClick}
        disabled={loading}
        aria-disabled={loading}
      >
        {loading ? (
          <Loading className="mx-auto" />
        ) : (
          <>
            <div />
            <span>{intl.formatMessage(Common_Confirm)}</span>
            <ArrowRightIcon />
          </>
        )}
      </button>
    </div>
  )
}
