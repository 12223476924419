import React from 'react'

type Props = {
  color?: string
  size?: string
}

export const TrashcanIcon = (props: Props) => (
  <svg
    height={props.size || '1.6rem'}
    width={props.size || '1.6rem'}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      fill: 'none',
      stroke: props.color || 'currentColor',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeWidth: '1.5px'
    }}
  >
    <path
      d="M610.1,3214.308H597.986a1.731,1.731,0,0,1-1.731-1.731V3197h15.577v15.577A1.732,1.732,0,0,1,610.1,3214.308Z"
      transform="translate(-592.793 -3193.539)"
    />
    <path d="M600.755,3208.423V3201.5" transform="translate(-592.101 -3192.846)" />
    <path d="M605.255,3208.423V3201.5" transform="translate(-591.409 -3192.846)" />
    <path d="M593.255,3197h22.5" transform="translate(-593.255 -3193.539)" />
    <path
      d="M606.178,3194h-5.192a1.731,1.731,0,0,0-1.731,1.731v1.731h8.654v-1.731A1.732,1.732,0,0,0,606.178,3194Z"
      transform="translate(-592.332 -3194)"
    />
  </svg>
)
