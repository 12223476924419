import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Modal, Loading } from '../../shared/common'
import { usePayoutMethodsQuery, usePayoutMethodMutation } from '../../../hooks'
import { intl } from '../../../lib'
import {
  Common_CharityCampaign_PayoutDisclaimer,
  SetupCampaignPayout_SetAsPayoutConfirm,
  SetupCampaignPayout_PayoutIsActive,
  SetupCampaignPayout_SetAsPayout,
  Common_Cancel,
  Common_EditPayout
} from '../../../translations/messages'

interface Props {
  campaignName: string
  charityDisplayName: string
}

export const SetupCampaignPayout = ({ campaignName, charityDisplayName }: Props) => {
  const navigate = useNavigate()
  const { campaignId } = useParams()
  const { data: payoutMethods } = usePayoutMethodsQuery()
  const updateQuery = usePayoutMethodMutation()
  const [showDrawer, setShowDrawer] = useState(false)
  const isActivePayout = payoutMethods?.some(method => method.active && method.recipient === campaignName)

  useEffect(() => {
    if (updateQuery.isSuccess) {
      setShowDrawer(false)
    }
  }, [updateQuery.isSuccess])

  return (
    <>
      {payoutMethods && (
        <div>
          {isActivePayout ? (
            <>
              <small>
                <b className="text-teal mb-5 block">
                  {intl.formatMessage(SetupCampaignPayout_PayoutIsActive, { charityName: charityDisplayName })}
                </b>
              </small>

              <button className="btn w-full" onClick={() => navigate('/payoutsettings')}>
                {intl.formatMessage(Common_EditPayout)}
              </button>
            </>
          ) : (
            <button className="btn primary w-full" onClick={() => setShowDrawer(true)}>
              {intl.formatMessage(SetupCampaignPayout_SetAsPayout)}
            </button>
          )}
        </div>
      )}

      <Modal onClose={() => setShowDrawer(false)} isOpen={showDrawer}>
        <div className="max-w-mobile mx-auto px-5 py-10 space-y-5">
          <h1 className="text-3xl">{intl.formatMessage(Common_EditPayout)}</h1>

          <p>{intl.formatMessage(Common_CharityCampaign_PayoutDisclaimer)}</p>

          <p>{intl.formatMessage(SetupCampaignPayout_SetAsPayoutConfirm)}</p>

          <div className="flex flex-col space-y-3">
            <button
              className="btn primary"
              aria-disabled={updateQuery.isPending}
              disabled={updateQuery.isPending}
              onClick={() => updateQuery.mutate({ type: 'DONATION', method: 'POST', recipient: campaignId })}
            >
              {updateQuery.isPending ? <Loading /> : intl.formatMessage(SetupCampaignPayout_SetAsPayout)}
            </button>

            <button className="btn" onClick={() => setShowDrawer(false)}>
              {intl.formatMessage(Common_Cancel)}
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}
