import React from 'react'

type Props = {
  color?: string
  size?: string
}

export const UserLocationIcon = (props: Props) => (
  <svg
    height={props.size || '1.6rem'}
    viewBox="0 0 24 24"
    width={props.size || '1.6rem'}
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fill: 'none',
      stroke: props.color || 'currentColor',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeWidth: '1.5px'
    }}
  >
    <circle cx="12" cy="12" r="3" />
    <circle cx="12" cy="12" r="7.5" />
    <line x1="12" y1="0.75" x2="12" y2="4.5" />
    <line x1="0.75" y1="12" x2="4.5" y2="12" />
    <line x1="12" y1="23.25" x2="12" y2="19.5" />
    <line x1="23.25" y1="12" x2="19.5" y2="12" />
  </svg>
)
