import React, { useEffect } from 'react'
import { openRateUsDialog } from '../../services'
import { Currency } from '../shared'
import { intl } from '../../lib'
import { useRefundsQuery } from '../../hooks'
import {
  RefundStatisticsSwiper_AvailableBalance,
  RefundStatisticsSwiper_TotalEarnings,
  RefundStatisticsSwiper_TotalPaidOut
} from '../../translations/messages'

export const RefundStatistics = () => {
  const { data } = useRefundsQuery()

  useEffect(() => {
    let timeoutRef

    if (data?.payoutStatistics?.totalAmount) {
      // Just to wait a bit before opening the rating dialog
      timeoutRef = setTimeout(openRateUsDialog, 2000)
    }

    return () => {
      clearTimeout(timeoutRef)
    }
  }, [data])

  return data ? (
    <div className="flex items-center justify-around text-center">
      <div>
        <small>{intl.formatMessage(RefundStatisticsSwiper_AvailableBalance)}</small>
        <h2>
          <Currency amount={data.balanceStatistics?.totalAmount || 0} currency={data.currency || 'AUD'} />
        </h2>
      </div>

      <div>
        <small>{intl.formatMessage(RefundStatisticsSwiper_TotalPaidOut)}</small>
        <h2>
          <Currency amount={data.payoutStatistics?.totalAmount || 0} currency={data.currency || 'AUD'} />
        </h2>
      </div>

      <div>
        <small>{intl.formatMessage(RefundStatisticsSwiper_TotalEarnings)}</small>
        <h2>
          <Currency amount={data.recycledStatistics?.totalAmount || 0} currency={data.currency || 'AUD'} />
        </h2>
      </div>
    </div>
  ) : (
    <div className="flex items-center justify-between px-5">
      {[1, 2, 3].map((_, index) => (
        <div className="flex flex-col items-center space-y-4" key={index}>
          <div className="skeleton-heading w-32"></div>
          <div className="skeleton-paragraph w-10"></div>
        </div>
      ))}
    </div>
  )
}
