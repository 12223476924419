import React from 'react'
import { intl } from '../../lib'
import {
  Locations_StatusIndicators_Closed,
  Locations_StatusIndicators_ClosedForService,
  Locations_StatusIndicators_Open,
  Locations_StatusIndicators_SemiFull,
  Locations_StatusIndicators_TitleCentre,
  Locations_StatusIndicators_TitleGlass,
  Locations_StatusIndicators_TitlePet,
  Locations_StatusIndicators_TitlePlasticAndCans,
  Common_Unknown
} from '../../translations/messages'

const titleByStatusType = {
  glass: Locations_StatusIndicators_TitleGlass,
  plasticAndCans: Locations_StatusIndicators_TitlePlasticAndCans,
  centre: Locations_StatusIndicators_TitleCentre,
  PET: Locations_StatusIndicators_TitlePet
}

const descriptionByStatus = {
  OPEN: Locations_StatusIndicators_Open,
  CLOSED: Locations_StatusIndicators_Closed,
  CLOSED_FOR_SERVICE: Locations_StatusIndicators_ClosedForService,
  SEMI_FULL: Locations_StatusIndicators_SemiFull,
  UNKNOWN: Common_Unknown
}

const statusColorMap = {
  OPEN: 'text-teal-dark dark:text-teal-light',
  CLOSED_FOR_SERVICE: 'text-warm-red-dark dark:text-warm-red-light',
  CLOSED: 'text-warm-red-dark dark:text-warm-red-light',
  UNKNOWN: 'black',
  SEMI_FULL: 'text-yellow-dark dark:text-yellow-light'
}

type Props = {
  locationStatuses: MapLocationType['status']
}

export const LocationStatus = ({ locationStatuses }: Props) => {
  const statusTypes = Object.keys(locationStatuses || {})

  const indicators = statusTypes.map(type => {
    const statusItem = locationStatuses && locationStatuses[type]

    return (
      statusItem && (
        <div className="relative grow border-t pt-2" key={type}>
          <small className="flex flex-col items-center">
            <p>{intl.formatMessage(titleByStatusType[type])}</p>

            <p className={statusColorMap[statusItem]}>{intl.formatMessage(descriptionByStatus[statusItem])}</p>
          </small>
        </div>
      )
    )
  })

  return <div className="flex items-center p-2">{indicators}</div>
}
