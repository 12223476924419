import React from 'react'

type Props = {
  color?: string
  size?: string
}

export const ArrowLeftIcon = (props: Props) => (
  <svg
    height={props.size || '0.87rem'}
    viewBox="0 0 7.19 12.38"
    width={props.size || '0.87rem'}
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fill: props.color || 'currentColor'
    }}
  >
    <path d="M.29,6.9l5.19,5.19A1,1,0,0,0,6.9,10.67L2.41,6.19,6.9,1.71A1,1,0,0,0,6.9.29a1,1,0,0,0-1.42,0L.29,5.48A1,1,0,0,0,.29,6.9Z" />
  </svg>
)
