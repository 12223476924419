import React, { useState } from 'react'
import {
  BrowseCharitiesPage_NoMatch,
  BrowseCharitiesPage_ShowingCampaigns,
  BrowseCharitiesPage_Filter,
  BrowseCharitiesPage_SortBy,
  BrowseCharitiesPage_ClearSorting,
  BrowseCharitiesPage_ClearFilter,
  BrowseCharitiesPage_SearchPlaceholder,
  Common_More
} from '../../../translations/messages'
import { useCharitySearchQuery } from '../../../hooks'
import { benefitOptions, orderOptions } from '../common/filterOptions'
import { CharityCampaignCard, Filter } from '../common'
import { SearchIcon } from '../../icons'
import { Loading } from '../../shared/common'
import { intl } from '../../../lib'

const Loader = () => (
  <div className="flex items-center space-x-5 card p-5 w-full">
    <div className="skeleton-circle min-w-16 min-h-16"></div>

    <div className="space-y-4 flex flex-col">
      <div className="skeleton-heading w-32"></div>
      <div className="skeleton-paragraph w-20"></div>
      <div className="skeleton-paragraph w-16"></div>
    </div>
  </div>
)

type InnerProps = {
  campaigns: CharityCampaignType[]
  totalCampaigns: number
  isLoading: boolean
  isFetchingNextPage: boolean
}

const Inner = ({ campaigns, totalCampaigns, isLoading, isFetchingNextPage }: InnerProps) => {
  const hasResults = campaigns.length > 0

  if (isLoading) {
    return (
      <div className="mt-5 space-y-5">
        {[1, 2, 3].map((_, i) => (
          <Loader key={i} />
        ))}
      </div>
    )
  }

  if (!hasResults) {
    return <div className="alert info">{intl.formatMessage(BrowseCharitiesPage_NoMatch)}</div>
  }

  if (hasResults) {
    return (
      <div>
        <small>{intl.formatMessage(BrowseCharitiesPage_ShowingCampaigns, { num: totalCampaigns ?? 0 })}</small>

        <div className="flex flex-col space-y-3">
          {campaigns.map(campaign => (
            <CharityCampaignCard campaign={campaign} key={campaign.id} />
          ))}

          {isFetchingNextPage && <Loader />}
        </div>
      </div>
    )
  }

  return null
}

export const BrowseCharitiesPage = () => {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [searchFilter, setSearchFilter] = useState<CharityBenefitType | ''>('')
  const [searchOrder, setSearchOrder] = useState<CharitySearchOrderType | ''>('')

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } = useCharitySearchQuery(
    searchTerm,
    searchFilter,
    searchOrder
  )

  const campaigns = (data && data.pages.map(page => page.campaigns).flat()) || []
  const totalCampaigns = data?.pages[0].totalCampaigns || 0

  return (
    <div className="py-5 px-8 flex flex-col max-w-mobile mx-auto space-y-5">
      <div className="input-field">
        <label className="label" htmlFor="search">
          {intl.formatMessage(BrowseCharitiesPage_SearchPlaceholder)}
        </label>

        <input
          type="text"
          id="search"
          className="input-text input-with-icon w-full"
          value={searchTerm}
          onChange={evt => {
            setSearchTerm(evt.target.value)
          }}
        />

        <div className="icon">
          <SearchIcon />
        </div>
      </div>

      <div className="flex items-center justify-between space-x-3">
        <Filter
          label={
            searchFilter ? BrowseCharitiesPage_ClearFilter.defaultMessage : BrowseCharitiesPage_Filter.defaultMessage
          }
          options={benefitOptions}
          defaultValue={searchFilter ?? ''}
          onChange={newValue => {
            setSearchFilter(newValue as CharityBenefitType)
          }}
        />
        <Filter
          label={
            searchOrder ? BrowseCharitiesPage_ClearSorting.defaultMessage : BrowseCharitiesPage_SortBy.defaultMessage
          }
          options={orderOptions}
          defaultValue={searchOrder ?? ''}
          onChange={newValue => {
            setSearchOrder(newValue as CharitySearchOrderType)
          }}
        />
      </div>

      <Inner
        campaigns={campaigns}
        isFetchingNextPage={isFetchingNextPage}
        isLoading={isLoading}
        totalCampaigns={totalCampaigns}
      />

      {hasNextPage && (
        <button className="btn" onClick={() => fetchNextPage()}>
          {isFetchingNextPage ? <Loading /> : intl.formatMessage(Common_More)}
        </button>
      )}
    </div>
  )
}
